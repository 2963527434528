import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "source/api/",
  // baseURL: "http://localhost/DISPENSINGMAIN/dispensing-mock/backend/source/api/",
});

export const body = {
  page_limit: JSON.stringify(10000),
  page_number: JSON.stringify(0 + 1),
  search_key: "",
};
const FacilityId = localStorage.getItem("FacilityId");
const UseFor = localStorage.getItem("UseFor");

const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
const UserName = UserInfo == 0 ? "" : UserInfo[0].id;
const lan = localStorage.getItem("LangCode");

const auth_token = sessionStorage.getItem("token")
  ? sessionStorage.getItem("token")
  : null;

let options = {};
options = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${auth_token}`,
  },
};

//Products Api
export const getProducts = () =>
  api
    .post(
      "productEntry",
      { ...body, action: "getDataList", menukey: "products" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getProduct = (id) =>
  api.post(
    `productEntry`,
    { ...body, action: "getProduct", menukey: "products", ItemNo: id },
    options
  );

export const updateProduct = ({ id, ...updatedProduct }) =>
  api
    .put(
      `productEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "products",
        ItemNo: id,
        ...updatedProduct,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const saveProduct = (productData) =>
  api
    .post(
      `productEntry`,
      { ...body, action: "dataInsert", menukey: "products", ...productData },
      options
    )
    .then((res) => res.data);

export const deleteProduct = (id) =>
  api
    .post(
      `productEntry`,
      {
        ...body,
        action: "dataDalete",
        menukey: "products",
        ItemNo: id,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => res.data);

//prescriber

// export const getPrescribers = () =>
//   api
//     .post(
//       "prescriber",
//       { ...body, action: "getDataList", menukey: "prescriber" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });

// export const getPrescriber = (id) =>
//   api.post(
//     `prescriber`,
//     {
//       ...body,
//       action: "getPrescriber",
//       menukey: "prescriber",
//       PrescriberId: id,
//     },
//     options
//   );

// export const updatePrescriber = ({ id, ...updatedPrescriber }) =>
//   api
//     .put(
//       `prescriber`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "prescriber",
//         PrescriberId: id,
//         ...updatedPrescriber,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data;
//     });

// export const savePrescriber = (prescriberData) =>
//   api
//     .post(
//       `prescriber`,
//       {
//         ...body,
//         action: "dataInsert",
//         menukey: "prescriber",
//         ...prescriberData,
//       },
//       options
//     )
//     .then((res) => res.data);

// export const deletePrescriber = (id) =>
//   api
//     .post(
//       `prescriber`,
//       {
//         ...body,
//         action: "dataDalete",
//         menukey: "prescriber",
//         PrescriberId: id,
//         UserName: UserName,
//         lan: lan,
//       },
//       options
//     )
//     .then((res) => res.data);

// Dispenser Api
// export const getDispensers = () =>
//   api
//     .post(
//       "dispenser",
//       { ...body, action: "getDataList", menukey: "dispenser" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });

// export const getDispenser = (id) =>
//   api.post(
//     `dispenser`,
//     { ...body, action: "getDispenser", menukey: "dispenser", DispenserId: id },
//     options
//   );

// export const updateDispenser = ({ id, ...updatedDispenser }) =>
//   api
//     .put(
//       `dispenser`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "dispenser",
//         DispenserId: id,
//         ...updatedDispenser,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data;
//     });

// export const saveDispenser = (dispenserData) =>
//   api
//     .post(
//       `dispenser`,
//       { ...body, action: "dataInsert", menukey: "dispenser", ...dispenserData },
//       options
//     )
//     .then((res) => res.data);

// export const deleteDispenser = (id) =>
//   api
//     .post(
//       `dispenser`,
//       {
//         ...body,
//         action: "dataDalete",
//         menukey: "dispenser",
//         DispenserId: id,
//         UserName: UserName,
//         lan: lan,
//       },
//       options
//     )
//     .then((res) => res.data);

// Protocol Api
// export const getProtocols = () =>
//   api
//     .post("getProtocolList", { ...body, menukey: "protocol" }, options)
//     .then((res) => {
//       return res.data.getprotocolList;
//     });

// export const getProtocol = (id) =>
//   api.post(
//     `protocolListSingle`,
//     { ...body, menukey: "protocol", ProtocolId: id },
//     options
//   );
// export const updateProtocol = ({ id, ...updatedProtocol }) =>
//   api
//     .put(
//       `protocolUpdate`,
//       { ...body, menukey: "order", ...updatedProtocol },
//       options
//     )
//     .then((res) => {
//       return res.data;
//     });
// export const saveProtocol = (protocolData) =>
//   api.post(`protocolSave`, protocolData, options).then((res) => res.data);
// export const deleteProtocol = (id) =>
//   api
//     .post(
//       `protocolDelete`,
//       {
//         ...body,
//         menukey: "protocol",
//         ProtocolId: id,
//         UserName: UserName,
//         lan: lan,
//       },
//       options
//     )
//     .then((res) => res.data);

export const getWarehouseList = (amcData) => {
  return api.post("comboscript", { ...amcData }, options).then((res) => {
    return res;
  });
};

//Stock Status api
export const getstockstatus = (params) =>
  api.post("StockStatus", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getLotDataView = (paramsList) =>
  api
    .post(
      "StockStatus",
      {
        ...body,
        action: "getLotDataView",
        menukey: "stock-status",
        ItemNo: paramsList[0],
        FilterDateMaster: paramsList[1],
        FilterRadioMaster: paramsList[2],
        FacilityId: FacilityId,
        UseFor: UseFor,
      },
      options
    )
    .then((res) => {
      console.log("paramsList api page: ", paramsList);
      return res.data.datalist;
    });

export const getStockCardDataView = (params) =>
  api.post("StockStatus", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getChartDataView = (params) =>
  api.post("StockStatus", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Modal


export const getProductsList = () =>
  api
    .post(
      "products",
      { ...body, action: "getProducts", menukey: "products" },
      options
    )
    .then((res) => {
      return res.data.productlist;
    });
export const getProductsLot = (id) =>
  api
    .post(
      "products",
      { ...body, action: "getProductLot", menukey: "products", FacilityId: id },
      options
    )
    .then((res) => {
      console.log("id from lot modal: ", id);
      return res.data.productlist;
    });

    /*
    export const getIssuedSingleDetails = (id) =>
    api
      .post(
        "products",
        { ...body, action: "getIssuedSingleInvoice", menukey: "products", FacilityId: id },
        options
      )
      .then((res) => {
        return res.data.productlist;
      }); 

      */
      
      export const getIssuedSingleDetails = (params) =>
      api
        .post("products", { params }, options)
        .then((res) => {
          return res.data.productlist;
        });   

export const getProductsLotIssueVoucher = (FacilityId, transferFacilityCode) =>
  api
    .post(
      "products",
      {
        ...body,
        action: "getProductLotIssueVoucher",
        menukey: "products",
        FacilityId: FacilityId,
        transferFacilityCode: transferFacilityCode,
      },
      options
    )
    .then((res) => {
      console.log("FacilityId from lot modal: ", FacilityId);
      console.log(
        "transferFacilityCode from lot modal: ",
        transferFacilityCode
      );
      return res.data.productlist;
    });

export const getProductsLotUnusable = (id) =>
  api
    .post(
      "products",
      {
        ...body,
        action: "getProductLotUnusable",
        menukey: "products",
        FacilityId: id,
      },
      options
    )
    .then((res) => {
      return res.data.productlist;
    });

export const getSingleProductLots = (manyTableCurrentRow) =>
  api
    .post(
      "products",
      {
        ...body,
        action: "getSingleProductLots",
        menukey: "products",
        manyTableCurrentRow: manyTableCurrentRow,
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      //console.log("id from lot modal: ", id);
      return res.data.productlist;
    });

// export const getProtocolListModal = () =>
//   api
//     .post(
//       "protocolModal",
//       { ...body, action: "getProtocolListModal", menukey: "protocol" },
//       options
//     )
//     .then((res) => {
//       return res.data.protocollist;
//     });

// export const getProductSelectInPrescriptionModal = (params) =>
//   api
//     .post(
//       "protocolModal",
//       {
//         ...body,
//         action: "getProductBatchModal",
//         menukey: "protocol",
//         FacilityId: FacilityId,
//         formulation: params.queryKey[1],
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.productlist;
//     });

// export const getProtocolItemListModal = () =>
//   api
//     .post(
//       "protocolModal",
//       { ...body, action: "getProtocolItemListModal", menukey: "protocol" },
//       options
//     )
//     .then((res) => {
//       return res.data.protocolitemlist;
//     });

export const getSourceList = () =>
  api
    .post(
      "sourceoffund",
      { ...body, action: "getSources", menukey: "receive" },
      options
    )
    .then((res) => {
      return res.data.sourcelist;
    });

///Receive

export const getReceiveInvs = (params) =>
  api.post("receiveinvoicelist", { params }, options).then((res) => {
    return res.data.receiveinvdata;
  });
export const saveReceiveInv = (dispenserData) =>
  api.post(`receiveSave`, { ...body, ...dispenserData,UserName: UserName, lan: lan}, options).then((res) => res.data);

export const updateReceiveInv = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `receiveUpdate`,
      {
        ...body,
        menukey: "receive",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getReceiveInv = (id) =>
  api.post(
    `receiveListSingle`,
    { ...body, menukey: "receive", TransactionId: id, FacilityId: FacilityId },
    options
  );
export const deleteReceiveInv = (id) =>
  api
    .post(
      `receiveDelete`,
      {
        ...body,
        menukey: "receive",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });
export const getNonReceivedOrderList = () =>
  api
    .post(
      "receivedNonOrderList",
      { ...body, menukey: "receive", FacilityId: FacilityId },
      options
    )
    .then((res) => {
      return res.data.receiveinvdata;
    });
export const saveNonReceivedOrder = (id) =>
  api
    .post(
      `receivedNonReceiveOrderProducts`,
      { ...body, menukey: "receive", FacilityId: FacilityId, OrderId: id },
      options
    )
    .then((res) => {
      return res.data.receiveinvdata;
    });
export const getInvoiceNo = (params) =>
  api.post("InvoiceNo", { ...body, params }, options).then((res) => {
    return res.data;
  });

export const getOrdInvoiceNo = (params) =>
  api.post("OrdInvoiceNo", { ...body, params }, options).then((res) => {
    return res.data;
  });

export const receiveInvoiceCancel = (id) =>
  api
    .post(
      `receiveInvoiceCancel`,
      {
        ...body,
        menukey: "receive",
        TransactionId: id,
        TransactionTypeId: 2,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const postRecFromWHInvoice = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "receive",
        FacilityId: FacilityId,
        TableName: "recinv",
        TransactionId: id,
        TransactionTypeId: 2,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });

//////////////////////////////////////

///Issue push
export const getIssueVoucherPush = (params) =>
  api.post("issueinvoicepush", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveIssueVoucherPushInv = (dispenserData) =>
  api
    .post(
      `issueinvoicepush`,
      {
        ...body,
        action: "IssueVoucherSave",
        menukey: "issuevoucherpush",
        UserName: UserName,
        lan: lan,
        ...dispenserData,
      },
      options
    )
    .then((res) => res.data);

export const issueVoucherUpdate = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `issueinvoicepush`,
      {
        ...body,
        action: "IssueVoucherUpdate",
        menukey: "issuevoucherpush",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getIssueVoucherSingle = (id) =>
  api.post(
    `issueinvoicepush`,
    {
      ...body,
      action: "getIssueVoucherSingle",
      menukey: "issuevoucherpush",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );
export const deleteIssueVoucherPushInv = (id) =>
  api
    .post(
      `issueinvoicepush`,
      {
        ...body,
        menukey: "issuevoucherpush",
        action: "deleteIssueVoucherPushInv",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });
export const postIssueVoucherPush = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "issuevoucherpush",
        FacilityId: FacilityId,
        TableName: "indent",
        TransactionId: id,
        TransactionTypeId: 1,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });
///////////////////////////////////////////

///Issue Indent
export const getIssueVoucherIndent = (params) =>
  api.post("issueinvoiceindent", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveIssueVoucherIndentInv = (dispenserData) =>
  api
    .post(
      `issueinvoiceindent`,
      {
        ...body,
        action: "IssueVoucherSave",
        menukey: "issuevoucherindent",
        UserName: UserName,
        lan: lan,
        ...dispenserData,
      },
      options
    )
    .then((res) => res.data);

export const issueVoucherIndentUpdate = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `issueinvoiceindent`,
      {
        ...body,
        action: "IssueVoucherUpdate",
        menukey: "issuevoucherindent",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getIssueVoucherIndentSingle = (id) =>
  api.post(
    `issueinvoiceindent`,
    {
      ...body,
      action: "getIssueVoucherSingle",
      menukey: "issuevoucherindent",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );
export const deleteIssueVoucherIndentInv = (id) =>
  api
    .post(
      `issueinvoiceindent`,
      {
        ...body,
        menukey: "issuevoucherindent",
        action: "deleteIssueVoucherPushInv",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });
export const postIssueVoucherIndent = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "issuevoucherindent",
        FacilityId: FacilityId,
        TableName: "indent",
        TransactionId: id,
        TransactionTypeId: 2,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });
///////////////////////////////////////////

///Form 7
export const getForm7 = (params) =>
  api.post("form7", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getTotalUpazilaCount = (params) =>
  api.post("form7", { ...body, params }, options).then((res) => {
    return res.data;
  });

export const saveForm7 = (dispenserData) =>
  api
    .post(
      `form7`,
      {
        ...body,
        action: "Form7Save",
        menukey: "form7",
        UserName: UserName,
        lan: lan,
        ...dispenserData,
      },
      options
    )
    .then((res) => res.data);

export const Form7Update = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `form7`,
      {
        ...body,
        action: "Form7Update",
        menukey: "form7",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getForm7Single = (id) =>
  api.post(
    `form7`,
    {
      ...body,
      action: "getForm7Single",
      menukey: "form7",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );
  export const getForm7SingleGlobal = (id) =>
  api.post(
    `form7`,
    {
      ...body,
      action: "getForm7SingleGlobal",
      menukey: "form7",
      TransactionId: id[0],
      FacilityId: id[1],
    },
    options
  );
export const deleteForm7 = (id) =>
  api
    .post(
      `form7`,
      {
        ...body,
        menukey: "form7",
        action: "deleteForm7",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const deleteForm7Items = (id) =>
  api
    .post(
      `form7`,
      {
        ...body,
        menukey: "form7",
        action: "deleteForm7Items",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getHasUnPostInvoiceByMonth = (params) =>
  api
    .post(
      "form7",
      {
        ...body,
        action: "getHasUnPostInvoiceByMonth",
        menukey: "form7",
        FacilityId: FacilityId,
        YearId: params.queryKey[1],
        MonthId: params.queryKey[2],
      },
      options
    )
    .then((res) => {
      return res.data.isunpostinv;
    });

export const getInvoiceStatusByMonth = (params) =>
  api
    .post(
      "form7",
      {
        ...body,
        action: "getInvoiceStatus",
        menukey: "form7",
        FacilityId: FacilityId,
        YearId: params.queryKey[1],
        MonthId: params.queryKey[2],
      },
      options
    )
    .then((res) => {
      return res.data.productlist;
    });

export const generateForm7Items = (id) =>
  api
    .post(
      `form7`,
      {
        ...body,
        menukey: "form7",
        FacilityId: FacilityId,
        UseFor: UseFor,
        action: "generateForm7Items",
        TransactionId: id,
        TransactionTypeId: 0,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });

///////////////////////////////////////////

///Form 7B
export const getForm7B = (params) =>
  api.post("form7b", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getForm7BSingle = (id) =>
  api.post(
    `form7b`,
    {
      ...body,
      action: "getForm7BSingle",
      menukey: "form7b",
      TransactionId: id[0],
      TransferFacilityCode: id[1],
      FacilityId: FacilityId,
      UseFor: UseFor,
    },
    options
  );

  export const getForm7BSingleglobal = (id) =>
  api.post(
    `form7b`,
    {
      ...body,
      action: "getForm7BSingleGlobal",
      menukey: "form7b",
      TransactionId: id[0],
      TransferFacilityCode: id[1],
      FacilityId: id[2],
      
    },
    options
  );

///////////////////////////////////////////

///Form 7
export const getSupplyPlanList = (params) =>
  api.post("supplyplan", { params }, options).then((res) => {
    return res.data.datalist;
  });

// export const getTotalUpazilaCount = (params) =>
//   api.post("form7", { ...body, params }, options).then((res) => {
//     return res.data;
//   });
export const getSupplySettingItems = (id) =>
  api.post(
    `supplyplan`,
    {
      ...body,
      action: "getSupplySettingItems",
      menukey: "supply-plan",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const saveSupplyPlan = (dispenserData) =>
  api
    .post(
      `supplyplan`,
      {
        ...body,
        action: "SupplyPlanSave",
        menukey: "supply-plan",
        ...dispenserData,
        UserName: UserName,
        lan: lan,
        UseFor: UseFor,
      },
      options
    )
    .then((res) => res.data);

export const SupplyPlanUpdate = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `supplyplan`,
      {
        ...body,
        action: "SupplyPlanUpdate",
        menukey: "supply-plan",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getSupplyPlanSingle = (id, currItemCode) =>
  api.post(
    `supplyplan`,
    {
      ...body,
      action: "getSupplyPlanSingle",
      menukey: "supply-plan",
      TransactionId: id,
      FacilityId: FacilityId,
      ItemCode: currItemCode,
    },
    options
  );
export const deleteSupplyPlan = (id) =>
  api
    .post(
      `supplyplan`,
      {
        ...body,
        menukey: "supply-plan",
        action: "deleteSupplyPlanMasterItems",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const deleteSupplyPlanItems = (id) =>
  api
    .post(
      `supplyplan`,
      {
        ...body,
        menukey: "supply-plan",
        action: "deleteSupplyPlanItems",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const generateSupplyPlanItems = (id) =>
  api
    .post(
      `supplyplan`,
      {
        ...body,
        menukey: "supply-plan",
        FacilityId: FacilityId,
        action: "generateSupplyPlanItems",
        TransactionId: id,
        TransactionTypeId: 0,
        UserName: UserName,
        lan: lan,
        UseFor: UseFor,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const generateIssueVoucher = (id) =>
  api
    .post(
      `supplyplan`,
      {
        ...body,
        menukey: "supply-plan",
        FacilityId: FacilityId,
        action: "generateIssueVoucher",
        TransactionId: id,
        TransactionTypeId: 0,
        UserName: UserName,
        UseFor: UseFor,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });

///////////////////////////////////////////

////////////custom supply plan///////////////

export const getCustomSupplyPlanItems = () =>
  api
    .post(
      "customsupplyplan",
      {
        ...body,
        action: "getCustomSupplyPlanItems",
        UseFor: UseFor,
        FacilityId: FacilityId,
        menukey: "custom-supply-plan",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const getProfileItems = (defaultProfId) =>
  api
    .post(
      "customsupplyplan",
      {
        ...body,
        action: "getProfileItems",
        FacilityId: FacilityId,
        menukey: "custom-supply-plan",
        Params: defaultProfId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getCustomSupplyPlanFacility = () =>
  api
    .post(
      "customsupplyplan",
      {
        ...body,
        action: "getCustomSupplyPlanFacility",
        UseFor: UseFor,
        FacilityId: FacilityId,
        menukey: "custom-supply-plan",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getCustomSupplyPlanFacilityAndProductFinal = () =>
  api
    .post(
      "customsupplyplan",
      {
        ...body,
        action: "getCustomSupplyPlanFacilityAndProductFinal",
        FacilityId: FacilityId,
        menukey: "custom-supply-plan",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const CustomSupplyPlanFacilityProductInsUp = (formData) =>
  api
    .post(
      `customsupplyplan`,
      {
        ...body,
        menukey: "custom-supply-plan",
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
        action: "CustomSupplyPlanFacilityProductInsUp",
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const addProfile = ({ id, ...paramsData }) =>
  api
    .put(
      `customsupplyplan`,
      {
        ...body,
        action: "addProfile",
        menukey: "custom-supply-plan",
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
        ...paramsData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const copyProfile = ({ id, ...paramsData }) =>
  api
    .put(
      `customsupplyplan`,
      {
        ...body,
        action: "copyProfile",
        menukey: "custom-supply-plan",
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
        ...paramsData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });
export const addProfileItemAndFacilityMap = ({ id, ...paramsData }) =>
  api
    .put(
      `customsupplyplan`,
      {
        ...body,
        action: "addProfileItemAndFacilityMap",
        menukey: "custom-supply-plan",
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
        ...paramsData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

///LMIS Form 2
export const getLMISForm2List = (params) =>
  api.post("lmisform2", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getLMISForm2SettingItems = (id) =>
  api.post(
    `lmisform2`,
    {
      ...body,
      action: "getLMISForm2SettingItems",
      menukey: "lmisform2",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const saveLMISForm2 = (dispenserData) =>
  api
    .post(
      `lmisform2`,
      {
        ...body,
        action: "saveLMISForm2",
        menukey: "lmisform2",
        ...dispenserData,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => res.data);

export const LMISForm2Update = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `lmisform2`,
      {
        ...body,
        action: "LMISForm2Update",
        menukey: "lmisform2",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const LMISForm2UpdateOnlyMany = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `lmisform2`,
      {
        ...body,
        action: "LMISForm2UpdateOnlyMany",
        menukey: "lmisform2",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getLMISForm2Single = (id, currItemCode) =>
  api.post(
    `lmisform2`,
    {
      ...body,
      action: "getLMISForm2Single",
      menukey: "lmisform2",
      TransactionId: id,
      FacilityId: FacilityId,
      ItemCode: currItemCode,
    },
    options
  );
export const deleteLMISForm2 = (id) =>
  api
    .post(
      `lmisform2`,
      {
        ...body,
        menukey: "lmisform2",
        action: "deleteLMISForm2",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const deleteLMISForm2Items = (id) =>
  api
    .post(
      `lmisform2`,
      {
        ...body,
        menukey: "lmisform2",
        action: "deleteLMISForm2Items",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const generateLMISForm2Items = (id) =>
  api
    .post(
      `lmisform2`,
      {
        ...body,
        menukey: "lmisform2",
        FacilityId: FacilityId,
        action: "generateLMISForm2Items",
        TransactionId: id,
        TransactionTypeId: 0,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });

// export const generateIssueVoucher = (id) =>
//   api
//     .post(
//       `supplyplan`,
//       {
//         ...body,
//         menukey: "supply-plan",
//         FacilityId: FacilityId,
//         action: "generateIssueVoucher",
//         TransactionId: id,
//         TransactionTypeId: 0,
//         UserName: UserName,
//         lan: lan,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

///////////////////////////////////////////

export const updateAdjStock = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "adjustment",
        FacilityId: FacilityId,
        TransactionId: id,
        TransactionTypeId: 3,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

// ORDER
// export const getOrderInvs = (params) =>
//   api.post("orderInvoiceList", { params }, options).then((res) => {
//     return res.data.orderinvdata;
//   });

// export const getOrderInvApproval = (params) =>
//   api.post("orderInvoiceListApproval", { params }, options).then((res) => {
//     return res.data.orderinvdata;
//   });

// export const saveOrderInv = (dispenserData) =>
//   api
//     .post(`orderSave`, { ...body, menukey: "order", ...dispenserData }, options)
//     .then((res) => res.data);

// export const updateOrderInv = ({ id, ...updatedOrder }) =>
//   api
//     .put(`orderUpdate`, { ...body, menukey: "order", ...updatedOrder }, options)
//     .then((res) => {
//       return res;
//     });

// export const updateOrderApproval = ({ id, ...updatedOrder }) =>
//   api
//     .put(
//       `orderUpdateApproval`,
//       { ...body, menukey: "order", ...updatedOrder },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const getOrderInv = (id) =>
//   api.post(
//     `orderListSingle`,
//     { ...body, menukey: "order", OrderId: id, FacilityId: FacilityId },
//     options
//   );
// export const deleteOrderInv = (id) =>
//   api.post(
//     `orderDelete`,
//     { ...body, menukey: "order", OrderId: id, FacilityId: FacilityId },
//     options
//   );

// export const postOrderInv = (id) =>
//   api
//     .post(
//       `orderPost`,
//       {
//         ...body,
//         menukey: "order",
//         FacilityId: FacilityId,
//         OrderId: id,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const postOrderApproval = (id) =>
//   api
//     .post(
//       `orderPostApproval`,
//       {
//         ...body,
//         menukey: "order",
//         FacilityId: FacilityId,
//         OrderId: id,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// Stock Take
// export const getStockTakeInvs = (params) =>
//   api.post("stocktake", { params }, options).then((res) => {
//     return res.data.gstocktakelist;
//   });

// export const saveStockTakeInv = (stockTakeData) =>
//   api
//     .post(
//       `stocktake`,
//       {
//         ...body,
//         menukey: "stock-take",
//         action: "saveStockTake",
//         ...stockTakeData,
//         UserName: UserName,
//         lan: lan,
//       },
//       options
//     )
//     .then((res) => res.data);

// export const updateStockTakeInv = ({ id, ...updatedStockTake }) =>
//   api
//     .put(
//       `stocktake`,
//       {
//         ...body,
//         menukey: "stock-take",
//         action: "updateStockTake",
//         ...updatedStockTake,
//         UserName: UserName,
//         lan: lan,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data;
//     });
// export const getStockTakeInv = (id) =>
//   api.post(
//     `stocktake`,
//     {
//       ...body,
//       menukey: "stock-take",
//       StockTakeId: id,
//       FacilityId: FacilityId,
//       action: "stockTakeListSingle",
//     },
//     options
//   );
// export const deleteStockTakeInv = (id) =>
//   api
//     .post(
//       `stocktake`,
//       {
//         ...body,
//         menukey: "stock-take",
//         StockTakeId: id,
//         FacilityId: FacilityId,
//         action: "stockTakeDelete",
//         UserName: UserName,
//         lan: lan,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data;
//     });

// export const postStockTakeInv = (id) =>
//   api
//     .post(
//       `StockTakePosted`,
//       {
//         ...body,
//         menukey: "stock-take",
//         FacilityId: FacilityId,
//         StockTakeId: id,
//         UserName: UserName,
//         lan: lan,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data;
//     });

/* export const getunitofmeasurelist1 = (params) =>  api.post('unit_of_measure', {params }, options).then((res) =>{ 
	return res.data.datalist;
}) */

// Patients Api
// export const getPatients = (params) => {
//   return api.post("patients", { params }, options).then((res) => {
//     return res.data.datalist;
//   });

// };

// Get All Patients Api
// export const getAllPatients = (params) => {
//   return api.post("patients", { params }, options).then((res) => {
//     return res.data.datalist;
//   });
// };

// export const getLogisticDashboard = (params) => {
//   return api.post("LogisticsDashboard", { params }, options).then((res) => {
//     return res;
//   });
// };

// export const getConsumptionTrendsTopFiveProducts = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   return api
//     .post(
//       "LogisticsDashboard",
//       {
//         ...body,
//         action: "getConsumptionTrendsTopFiveProducts",
//         menukey: "Logistics Dashboard",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       // console.log("Res data",res);
//       //console.log("data list",res.data.datalist);
//       return res.data.datalist;
//     });
// };


// export const getAMCTrendsTopFiveProducts = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   return api
//     .post(
//       "LogisticsDashboard",
//       {
//         ...body,
//         action: "getAMCTrendsTopFiveProducts",
//         menukey: "Logistics Dashboard",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getPercentageOfFacilitiesWithStockoutOfTracerCommodities = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   return api
//     .post(
//       "LogisticsDashboard",
//       {
//         ...body,
//         action: "getPercentageOfFacilitiesWithStockoutOfTracerCommodities",
//         menukey: "Logistics Dashboard",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getPatient = (id) =>
//   api.post(
//     `patients`,
//     {
//       ...body,
//       menukey: "patients",
//       PatientId: id,
//       FacilityId: FacilityId,
//       action: "getPatient",
//     },
//     options
//   );

// export const updatePatient = ({ id, ...updatedPatient }) =>
//   api
//     .put(
//       `patients`,
//       {
//         ...body,
//         menukey: "patients",
//         ...updatedPatient,
//         action: "patientUpdate",
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const SaveUpdateAallergies = (patientId) => {
//   if (patientId.allergiesData1.AllergiesId == undefined)
//     return api
//       .post(
//         "patients",
//         {
//           menukey: "patients",
//           lan: lan,
//           UserName: UserName,
//           FacilityId: FacilityId,
//           action: "SaveAallergies",
//           ...patientId.allergiesData1,
//         },
//         options
//       )
//       .then((res) => {
//         console.log("res: ", res);
//         //res.data=res.data.list;
//         return res;
//       });
//   else {
//     return api
//       .put(
//         "patients",
//         {
//           menukey: "patients",
//           lan: lan,
//           UserName: UserName,
//           FacilityId: FacilityId,
//           action: "UpdateAallergies",
//           ...patientId.allergiesData1,
//         },
//         options
//       )
//       .then((res) => {
//         // res.data=res.data.list;
//         console.log("res: ", res);
//         return res;
//       });
//   }
// };

// export const deleteAlergies = (id) => {
//   return api
//     .put(
//       "patients",
//       {
//         menukey: "patients",
//         lan: lan,
//         UserName: UserName,
//         AllergiesId: id,
//         FacilityId: FacilityId,
//         action: "DeleteAallergies",
//       },
//       options
//     )
//     .then((res) => {
//       //res.data=res.data.list;
//       return res;
//     });
// };

// export const SaveUpdateLabresults = (patientId) => {
//   if (patientId.labResultsData1.labtestdetailId == undefined)
//     return api
//       .post(
//         "patients",
//         {
//           menukey: "patients",
//           lan: lan,
//           UserName: UserName,
//           FacilityId: FacilityId,
//           action: "SaveLabresults",
//           ...patientId.labResultsData1,
//         },
//         options
//       )
//       .then((res) => {
//         console.log("res: ", res);
//         //res.data=res.data.list;
//         return res;
//       });
//   else {
//     return api
//       .put(
//         "patients",
//         {
//           menukey: "patients",
//           lan: lan,
//           UserName: UserName,
//           FacilityId: FacilityId,
//           action: "UpdateLabresults",
//           ...patientId.labResultsData1,
//         },
//         options
//       )
//       .then((res) => {
//         //res.data=res.data.list;
//         console.log("res: ", res);
//         return res;
//       });
//   }
// };

// export const SaveUpdateAnthropometrics = (patientId) => {
//   if (patientId.anthropometricsData1.anthropometricsId == undefined)
//     return api
//       .post(
//         "patients",
//         {
//           menukey: "patients",
//           lan: lan,
//           UserName: UserName,
//           FacilityId: FacilityId,
//           action: "SaveAnthropometrics",
//           ...patientId.anthropometricsData1,
//         },
//         options
//       )
//       .then((res) => {
//         console.log("res: ", res);
//         //res.data=res.data.list;
//         return res;
//       });
//   else {
//     return api
//       .put(
//         "patients",
//         {
//           menukey: "patients",
//           lan: lan,
//           UserName: UserName,
//           FacilityId: FacilityId,
//           action: "UpdateAnthropometrics",
//           ...patientId.anthropometricsData1,
//         },
//         options
//       )
//       .then((res) => {
//         //res.data=res.data.list;
//         console.log("res: ", res);
//         return res;
//       });
//   }
// };

// export const deleteAnthropometrics = (patientId) => {
//   return api
//     .put(
//       "patients",
//       {
//         menukey: "patients",
//         lan: lan,
//         UserName: UserName,
//         anthropometricsId: patientId,
//         FacilityId: FacilityId,
//         action: "DeleteAnthropometrics",
//       },
//       options
//     )
//     .then((res) => {
//       //res.data=res.data.list;
//       return res;
//     });
// };

// export const deleteLabresults = (id) => {
//   return api
//     .put(
//       "patients",
//       {
//         menukey: "patients",
//         lan: lan,
//         UserName: UserName,
//         labtestdetailId: id,
//         FacilityId: FacilityId,
//         action: "DeleteLabresults",
//       },
//       options
//     )
//     .then((res) => {
//       //res.data=res.data.list;
//       return res;
//     });
// };

// export const savePatient = (patientData) =>
//   api
//     .post(
//       `patients`,
//       {
//         ...body,
//         FacilityId: FacilityId,
//         action: "patientInsert",
//         menukey: "patients",
//         ...patientData,
//       },
//       options
//     )
//     .then((res) => res.data);

// export const deletePatient = (id) => api.post(`patients`, id, options);

// export const getPrescriptionByPatientId = (patientId = null) => {
//   if (patientId == null) {
//     return api.post(
//       `patients`,
//       {
//         ...body,
//         menukey: "patients",
//         FacilityId: FacilityId,
//         action: "getPrescription",
//       },
//       options
//     );
//   } else {
//     return api
//       .post(
//         "patients",
//         {
//           ...body,
//           menukey: "patients",
//           FacilityId: FacilityId,
//           action: "getPrescription",
//           PatientId: patientId,
//         },
//         options
//       )
//       .then((res) => {
//         return res.data;
//       });
//   }
// };

// export const getCD4WeightChart = (id) => {
//   return api
//     .put(
//       "patients",
//       {
//         menukey: "patients",
//         PatientId: id,
//         FacilityId: FacilityId,
//         action: "getCD4WeightChart",
//       },
//       options
//     )
//     .then((res) => {
//       res.data = res.data.list;
//       return res;
//     });
// };

//Rubel.

/* Dashboard Main */

// export const getTLDUptakePatientsTrend = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   console.log(
//     "pYearId: ",
//     pYearId,
//     ", pMonthId: ",
//     pMonthId,
//     "pFacilityId: ",
//     pFacilityId
//   );

//   return api
//     .post(
//       "dashboardpage",
//       {
//         ...body,
//         action: "getTLDUptakePatientsTrend",
//         menukey: "dashboard",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       // console.log("patientdashboard patient count: ", res.data.datalist);

//       console.log(
//         "getTLDUptakePatientsTrend patient count: ",
//         res.data.datalist
//       );
//       return res.data.datalist;
//     });
// };

// export const getTLDTransitionPatientsTrend = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   console.log(
//     "pYearId: ",
//     pYearId,
//     ", pMonthId: ",
//     pMonthId,
//     "pFacilityId: ",
//     pFacilityId
//   );

//   return api
//     .post(
//       "dashboardpage",
//       {
//         ...body,
//         action: "getTLDTransitionPatientsTrend",
//         menukey: "dashboard",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       // console.log("patientdashboard patient count: ", res.data.datalist);

//       console.log(
//         "getTLDTransitionPatientsTrend patient count: ",
//         res.data.datalist
//       );
//       return res.data.datalist;
//     });
// };

// export const getMMDAmongAdultPatientsTrend = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   console.log(
//     "pYearId: ",
//     pYearId,
//     ", pMonthId: ",
//     pMonthId,
//     "pFacilityId: ",
//     pFacilityId
//   );

//   return api
//     .post(
//       "dashboardpage",
//       {
//         ...body,
//         action: "getMMDAmongAdultPatientsTrend",
//         menukey: "dashboard",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       // console.log("patientdashboard patient count: ", res.data.datalist);

//       console.log(
//         "getMMDAmongAdultPatientsTrend patient count: ",
//         res.data.datalist
//       );
//       return res.data.datalist;
//     });
// };

// export const getMMDCoveragePatientsTrend = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   console.log(
//     "pYearId: ",
//     pYearId,
//     ", pMonthId: ",
//     pMonthId,
//     "pFacilityId: ",
//     pFacilityId
//   );

//   return api
//     .post(
//       "dashboardpage",
//       {
//         ...body,
//         action: "getMMDCoveragePatientsTrend",
//         menukey: "dashboard",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       // console.log("patientdashboard patient count: ", res.data.datalist);

//       console.log(
//         "getMMDCoveragePatientsTrend patient count: ",
//         res.data.datalist
//       );
//       return res.data.datalist;
//     });
// };

// export const getDashboardStockStatusTableData = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   console.log(
//     "pYearId: ",
//     pYearId,
//     ", pMonthId: ",
//     pMonthId,
//     "pFacilityId: ",
//     pFacilityId
//   );

//   return api
//     .post(
//       "dashboardpage",
//       {
//         ...body,
//         action: "getDashboardStockStatusTableData",
//         menukey: "dashboard",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       // console.log("patientdashboard patient count: ", res.data.datalist);

//       console.log(
//         "getDashboardStockStatusTableData patient count: ",
//         res.data.datalist
//       );
//       return res.data.datalist;
//     });
// };

/* WIMS/UIMS Dashboard */

export const getCurrentMetaInfo = () => {
  return api
    .post(
      "dashboardpage",
      {
        ...body,
        action: "getDashboardMetaInfo",
        menukey: "dashboard",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      // console.log("patientdashboard patient count: ", res.data.datalist);

      console.log("patientdashboard patient count: ", res.data.datalist);
      return res.data.datalist;
    });
};



export const getPendingReceiveData = () => {

  return api.post("dashboardpage", 
    { ...body, action: "getPendingReceiveData", menukey: "dashboard", FacilityId: FacilityId, }, options)
    .then((res) => {
      return res.data.datalist;
    });
}

export const getWaitingForReceiveData = () => {

  return api.post("dashboardpage", 
    { ...body, action: "getWaitingForReceiveData", menukey: "dashboard", FacilityId: FacilityId, }, options)
    .then((res) => {
      return res.data.datalist;
    });
}

export const getCurrentStockStausChart = () => {
  return api
    .post(
      "dashboardpage",
      {
        ...body,
        action: "getCurrentStockStausChart",
        menukey: "dashboard",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      console.log(
        "getAdultPatientsByProtocol patient count: ",
        res.data.datalist
      );
      return res.data.datalist;
    });
};

export const getExpiryData = () => {
  return api
    .post(
      "dashboardpage",
      {
        ...body,
        action: "getExpiryData",
        menukey: "dashboard",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      console.log(
        "getPaediatricPatientsByProtocol patient count: ",
        res.data.datalist
      );
      return res.data.datalist;
    });
};

/* Patient Dashboard */


/*Start of MCWC Dashboard*/

export const getMCWCCurrentMetaInfo = () => {
  return api
    .post(
      "mcwc_dashboard",
      {
        ...body,
        action: "getMCWCDashboardMetaInfo",
        menukey: "mcwc-dashboard",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};


export const getMCWCPendingReceiveData = () => {
  return api.post("mcwc_dashboard", 
    { ...body, 
	action: "getMCWCPendingReceiveData", 
	menukey: "mcwc-dashboard", 
	FacilityId: FacilityId, 
	}, options)
    .then((res) => {
      return res.data.datalist;
    });
}

export const getMCWCWaitingForReceiveData = () => {

  return api.post("mcwc_dashboard", 
    { ...body, 
	action: "getMCWCWaitingForReceiveData", 
	menukey: "mcwc-dashboard", 
	FacilityId: FacilityId, 
	}, options)
    .then((res) => {
      return res.data.datalist;
    });
}

export const getNumberOfAssetStatusData = () => {

  return api.post("mcwc_dashboard", 
    { ...body, 
	action: "getNumberOfAssetStatusData", 
	menukey: "mcwc-dashboard", 
	FacilityId: FacilityId, 
	}, options)
    .then((res) => {
      return res.data.datalist;
    });
}

export const getMCWCCurrentStockStausChart = () => {
  return api
    .post(
      "mcwc_dashboard",
      {
        ...body,
        action: "getMCWCCurrentStockStausChart",
        menukey: "mcwc-dashboard",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getMCWCExpiryData = () => {
  return api
    .post(
      "mcwc_dashboard",
      {
        ...body,
        action: "getMCWCExpiryData",
        menukey: "mcwc-dashboard",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

/* End Off MCWC Dashboard */

// export const getCurrentMonthPatients = (pYearId, pMonthId, pFacilityId = 0) => {
//   console.log(
//     "pYearId: ",
//     pYearId,
//     ", pMonthId: ",
//     pMonthId,
//     "pFacilityId: ",
//     pFacilityId
//   );

//   return api
//     .post(
//       "patientdashboard",
//       {
//         ...body,
//         action: "getCurrentMonthPatients",
//         menukey: "Patient Dashboard",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       // console.log("patientdashboard patient count: ", res.data.datalist);

//       console.log("patientdashboard patient count: ", res.data.datalist);
//       return res.data.datalist;
//     });
// };

// export const getActivePatientsTrend = (pYearId, pMonthId, pFacilityId = 0) => {
//   console.log(
//     "pYearId: ",
//     pYearId,
//     ", pMonthId: ",
//     pMonthId,
//     "pFacilityId: ",
//     pFacilityId
//   );

//   return api
//     .post(
//       "patientdashboard",
//       {
//         ...body,
//         action: "getActivePatientsTrend",
//         menukey: "Patient Dashboard",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       // console.log("patientdashboard patient count: ", res.data.datalist);

//       console.log("getActivePatientsTrend patient count: ", res.data.datalist);
//       return res.data.datalist;
//     });
// };

// export const getPaediatricPatientsByProtocol = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   console.log(
//     "pYearId: ",
//     pYearId,
//     ", pMonthId: ",
//     pMonthId,
//     "pFacilityId: ",
//     pFacilityId
//   );

//   return api
//     .post(
//       "patientdashboard",
//       {
//         ...body,
//         action: "getPaediatricPatientsByProtocol",
//         menukey: "Patient Dashboard",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       console.log(
//         "getPaediatricPatientsByProtocol patient count: ",
//         res.data.datalist
//       );
//       return res.data.datalist;
//     });
// };

//Prescription entry form
// export const getPatientsForPrescription = (searchData = null) => {
//   console.log("getPatientsForPrescription API Params: ", searchData);

//   if (searchData == null) {
//     //return api.get('/patients').then((res) => res.data)
//     console.log("searchData is null");
//     return api
//       .post(
//         "patients",
//         {
//           ...body,
//           action: "getPatientList",
//           menukey: "patients",
//           FacilityId: FacilityId,
//         },
//         options
//       )
//       .then((res) => {
//         return res.data.datalist;
//       });
//   } else {
//     console.log("searchData is not null");
//     return api
//       .post(
//         "patients",
//         {
//           ...body,
//           action: "getPatientList",
//           menukey: "patients",
//           searchData: searchData,
//           FacilityId: FacilityId,
//         },
//         options
//       )
//       .then((res) => {
//         return res.data.datalist;
//       });
//   }
// };

// export const getPrescriptions = () =>
//   api.get("/prescriptions").then((res) => res.data);

// export const getPrescriptionByPatientIdDate = (
//   patientId = null,
//   fromDate,
//   toDate,
//   toggleButtonValue,
//   isDefaulterPrescription
// ) => {
//   console.log("getPrescriptionByPatientIdDate api.js");
//   console.log(
//     "patientId: ",
//     patientId,
//     ", fromDate: ",
//     fromDate,
//     ", toDate: ",
//     toDate,
//     ",FacilityId: ",
//     FacilityId,
//     ",toggleButtonValue: ",
//     toggleButtonValue,
//     ", IsDefaulterPrescription:",
//     isDefaulterPrescription
//   );
//   let currDate = new Date();
//   if (patientId == null) {
//     return api
//       .post(
//         "prescriptions",
//         {
//           ...body,
//           action: "getPrescriptionList",
//           menukey: "prescriptions",
//           fromDate: fromDate,
//           toDate: toDate,
//           FacilityId: FacilityId,
//           ToggleButtonValue: toggleButtonValue,
//           CurrDate: currDate,
//           IsDefaulterPrescription: isDefaulterPrescription,
//         },
//         options
//       )
//       .then((res) => {
//         console.log(
//           "prescriptions list api without patientid: ",
//           res.data.datalist
//         );
//         return res.data.datalist;
//       });
//   } else {
//     return api
//       .post(
//         "prescriptions",
//         {
//           ...body,
//           action: "getPrescriptionList",
//           menukey: "prescriptions",
//           patientId: patientId,
//           fromDate: fromDate,
//           toDate: toDate,
//           FacilityId: FacilityId,
//           ToggleButtonValue: toggleButtonValue,
//           CurrDate: currDate,
//           IsDefaulterPrescription: isDefaulterPrescription,
//         },
//         options
//       )
//       .then((res) => {
//         console.log("prescriptions list api by patientid: ", res.data.datalist);
//         return res.data.datalist;
//       });
//   }
// };

//call when going edit
// export const getPrescription = (PrescriptionId) => {
//   console.log("PrescriptionId: ", PrescriptionId);

//   return api
//     .post(
//       "prescriptions",
//       {
//         ...body,
//         action: "getPrescriptionByPrescriptionId",
//         menukey: "prescriptions",
//         PrescriptionId: PrescriptionId,
//       },
//       options
//     )
//     .then((res) => {
//       console.log("Single Prescriptions when Edit API: ", res.data.datalist);
//       return res.data.datalist;
//     });
// };

// export const updatePrescription = ({
//   PrescriptionId,
//   ...updatedPrescription
// }) =>
//   api
//     .put(
//       `prescriptions`,
//       {
//         ...body,
//         action: "updatePrescription",
//         menukey: "prescriptions",
//         PrescriptionId: PrescriptionId,
//         UserName: UserName,
//         lan: lan,
//         ...updatedPrescription,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const updatePrescriptionStatus = ({
//   PrescriptionId,
//   ...updatePrescriptionStatus
// }) =>
//   api
//     .put(
//       `prescriptions`,
//       {
//         ...body,
//         action: "updatePrescriptionStatus",
//         menukey: "prescriptions",
//         PrescriptionId: PrescriptionId,
//         UserName: UserName,
//         lan: lan,
//         ...updatePrescriptionStatus,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const postPrescription = ({
//   PrescriptionId,
//   ...updatePrescriptionStatus
// }) =>
//   api
//     .put(
//       `POSTStockForPrescription`,
//       {
//         ...body,
//         action: "postPrescription",
//         menukey: "prescriptions",
//         PrescriptionId: PrescriptionId,
//         UserName: UserName,
//         lan: lan,
//         ...updatePrescriptionStatus,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const updatePrescriptionCopy = ({
//   PrescriptionId,
//   ...updatePrescriptionCopy
// }) =>
//   api
//     .post(
//       `prescriptions`,
//       {
//         ...body,
//         action: "updatePrescriptionCopy",
//         menukey: "prescriptions",
//         PrescriptionId: PrescriptionId,
//         UserName: UserName,
//         lan: lan,
//         ...updatePrescriptionCopy,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const deletePrescription = (PrescriptionId) =>
//   api.post(
//     `prescriptions`,
//     {
//       ...body,
//       action: "deletePrescription",
//       menukey: "prescriptions",
//       PrescriptionId: PrescriptionId,
//       UserName: UserName,
//       lan: lan,
//     },
//     options
//   );

// export const savePrescription = (savePrescriptionData) =>
//   api
//     .post(
//       `prescriptions`,
//       {
//         ...body,
//         action: "savePrescription",
//         menukey: "prescriptions",
//         UserName: UserName,
//         lan: lan,
//         ...savePrescriptionData,
//       },
//       options
//     )
//     .then((res) => res.data);

// export const getProtocolItems = (ProtocolId) => {
//   console.log("getProtocolItems api.js. ProtocolId:", ProtocolId);

//   return api
//     .post(
//       "prescriptions",
//       {
//         ...body,
//         action: "getProtocolItems",
//         menukey: "prescriptions",
//         ProtocolId: ProtocolId,
//         FacilityId: FacilityId,
//       },
//       options
//     )
//     .then((res) => {
//       console.log("Protocol Items by ProtocolId: ", res.data.datalist);
//       return res.data.datalist;
//     });
// };

//combo api
//Local Storage from Login Page
export const getLoginAllDropdown = async (options, task) =>
  api
    .post(
      "comboscript",
      { action: "getAllDropdownList", task: task, menukey: "" },
      options
    )
    .then((res) => {
      return res.data;
    });
//Local Storage from meta or user entry page
export const getAllDropdown = (task) =>
  api
    .post(
      "comboscript",
      { action: "getAllDropdownList", task: task, menukey: "" },
      options
    )
    .then((res) => {
      return res.data;
    });

//ICD List Api
// export const getIcd = () =>
//   api
//     .post(
//       "comboscript",
//       { ...body, action: "getIcdList", menukey: "" },
//       options
//     )
//     .then((res) => {
//       return res.data;
//     });
//Local Storage from Login Page
export const getLoginFacilityListNotEqual99ANDSuppliers = async (
  options,
  task
) =>
  api
    .post(
      `comboscript`,
      {
        action: "getFacilityListNotEqual99ANDSupplier",
        task: task,
        menukey: "",
        FacilityId: localStorage.getItem("FacilityId"),
        UseFor: localStorage.getItem("UseFor"),
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });
//Local Storage from meta or user entry page
export const getFacilityListNotEqual99ANDSuppliers = (task, FacilityId) =>
  api
    .post(
      `comboscript`,
      {
        action: "getFacilityListNotEqual99ANDSupplier",
        task: task,
        menukey: "",
        FacilityId: FacilityId,
        UseFor: UseFor,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const UpdateAMC = (amcData) => {
  return api.post("StockStatus", { ...amcData }, options).then((res) => {
    return res;
  });
};

//Admin Forms
export const getTotal = (params) =>
  api.post("unit_of_measure", { params }, options).then((res) => {
    return res.data.datalist;
  });
//Admin Forms
export const getunitofmeasurelist1 = (params) =>
  api.post("unit_of_measure", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveunitofmeasure = (getunitofmeasure1) =>
  api
    .post(
      `unit_of_measure`,
      {
        ...body,
        action: "dataInsert",
        menukey: "Unitofmeasureentry",
        ...getunitofmeasure1,
      },
      options
    )
    .then((res) => res.data);
export const deleteunitofmeasure = (id) =>
  api.post(`unit_of_measure`, id, options);
export const getunitofmeasure = (id) =>
  api.post(
    `unit_of_measure`,
    {
      ...body,
      action: "getunit_measure",
      menukey: "Unitofmeasureentry",
      UnitId: id,
    },
    options
  );
export const updateunitof = ({ id, ...updatedunitof }) =>
  api
    .put(
      `unit_of_measure`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "Unitofmeasureentry",
        UnitId: id,
        ...updatedunitof,
      },
      options
    )
    .then((res) => {
      return res;
    });

//direction
// export const getdirection11 = () =>
//   api
//     .post(
//       "direction",
//       { ...body, action: "getdirectionlist", menukey: "direction" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// export const savedirection = (getdirection1) =>
//   api
//     .post(
//       `direction`,
//       { ...body, action: "dataInsert", menukey: "direction", ...getdirection1 },
//       options
//     )
//     .then((res) => res.data);

// export const deletedirection = (id) => api.post(`direction`, id, options);

// export const gedirections = (id) =>
//   api.post(
//     `direction`,
//     { ...body, action: "gedirection", menukey: "direction", DirectionId: id },
//     options
//   );

// export const updatedirection = ({ id, ...updatedirections }) =>
//   api
//     .put(
//       `direction`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "direction",
//         DirectionId: id,
//         ...updatedirections,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

//Interval
// export const getInterval11 = () =>
//   api
//     .post(
//       "interval",
//       { ...body, action: "getIntervalList", menukey: "interval" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// export const geIntevals = (id) =>
//   api.post(
//     `interval`,
//     { ...body, action: "gedirection", menukey: "interval", IntervalId: id },
//     options
//   );
// export const saveinverval = (getinverval1) =>
//   api
//     .post(
//       `interval`,
//       { ...body, action: "dataInsert", menukey: "interval", ...getinverval1 },
//       options
//     )
//     .then((res) => res.data);

// export const deleteinterval = (id) => api.post(`interval`, id, options);

// export const updateInterval = ({ id, ...updateintervals }) =>
//   api
//     .put(
//       `interval`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "interval",
//         IntervalId: id,
//         ...updateintervals,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

//LabTest
// export const getLabTest22 = () =>
//   api
//     .post(
//       "labtest",
//       { ...body, action: "getLabTestList", menukey: "labtest" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// export const geLabTests = (id) =>
//   api.post(
//     `labtest`,
//     { ...body, action: "gedLebTest", menukey: "labtest", LabTestId: id },
//     options
//   );
// export const saveLabTest = (getlabtest1) =>
//   api
//     .post(
//       `labtest`,
//       { ...body, action: "dataInsert", menukey: "labtest", ...getlabtest1 },
//       options
//     )
//     .then((res) => res.data);

// export const deleteLabTest = (id) => api.post(`labtest`, id, options);

// export const updateLabTest = ({ id, ...updatelabtests }) =>
//   api
//     .put(
//       `labtest`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "labtest",
//         LabTestId: id,
//         ...updatelabtests,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

//Route of admin
// export const getRouteofadmin33 = () =>
//   api
//     .post(
//       "routeofadmin",
//       { ...body, action: "getRouteOfAdminList", menukey: "routeofadmin" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });

// export const geRouteofadmins = (id) =>
//   api.post(
//     `routeofadmin`,
//     {
//       ...body,
//       action: "gedRouteofAdmin",
//       menukey: "routeofadmin",
//       RouteId: id,
//     },
//     options
//   );

// export const saveRouteofadmin = (getrouteofadmin1) =>
//   api
//     .post(
//       `routeofadmin`,
//       {
//         ...body,
//         action: "dataInsert",
//         menukey: "routeofadmin",
//         ...getrouteofadmin1,
//       },
//       options
//     )
//     .then((res) => res.data);

// export const deleteRouteofadmin = (id) => api.post(`routeofadmin`, id, options);

// export const updateRouteofadmin = ({ id, ...updateRouteofadmins }) =>
//   api
//     .put(
//       `routeofadmin`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "routeofadmin",
//         RouteId: id,
//         ...updateRouteofadmins,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

//Order type
// export const getOrderType24 = () =>
//   api
//     .post(
//       "ordertype",
//       { ...body, action: "getOrderTypeList", menukey: "routeofadmin" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });

// export const geOrderTypes = (id) =>
//   api.post(
//     `ordertype`,
//     {
//       ...body,
//       action: "gedOrderType",
//       menukey: "routeofadmin",
//       OrderTypeId: id,
//     },
//     options
//   );

// export const saveOrderType = (getordetype1) =>
//   api
//     .post(
//       `ordertype`,
//       {
//         ...body,
//         action: "dataInsert",
//         menukey: "routeofadmin",
//         ...getordetype1,
//       },
//       options
//     )
//     .then((res) => res.data);

// export const deleteOrderType = (id) => api.post(`ordertype`, id, options);

// export const updateOrderType = ({ id, ...updateOrderTypes }) =>
//   api
//     .put(
//       `ordertype`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "routeofadmin",
//         OrderTypeId: id,
//         ...updateOrderTypes,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

//title list
// export const getTitleLists51 = () =>
//   api
//     .post(
//       "titlelist",
//       { ...body, action: "getTitleList", menukey: "titlelist" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// export const geTitleList = (id) =>
//   api.post(
//     `titlelist`,
//     { ...body, action: "geTitle", menukey: "titlelist", TitleId: id },
//     options
//   );
// export const saveTitleList = (gettitlelist1) =>
//   api
//     .post(
//       `titlelist`,
//       { ...body, action: "dataInsert", menukey: "titlelist", ...gettitlelist1 },
//       options
//     )
//     .then((res) => res.data);

// export const deleteTitleList = (id) => api.post(`titlelist`, id, options);

// export const updateTitleList = ({ id, ...updateTitles }) =>
//   api
//     .put(
//       `titlelist`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "titlelist",
//         TitleId: id,
//         ...updateTitles,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

//marital status
// export const getmaritalstatuslist55 = () =>
//   api
//     .post(
//       "maritalstatus",
//       { ...body, action: "getmaritalstatusList", menukey: "maritalstatus" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });

// export const geMatitalstatus = (id) =>
//   api.post(
//     `maritalstatus`,
//     {
//       ...body,
//       action: "gemaritalstaus",
//       menukey: "maritalstatus",
//       MaritalStatusId: id,
//     },
//     options
//   );

// export const savemaritalstatus = (getmaritalstatus1) =>
//   api
//     .post(
//       `maritalstatus`,
//       {
//         ...body,
//         action: "dataInsert",
//         menukey: "maritalstatus",
//         ...getmaritalstatus1,
//       },
//       options
//     )
//     .then((res) => res.data);

// export const deletemaritalstatus = (id) =>
//   api.post(`maritalstatus`, id, options);

// export const updatemaritalstatus = ({ id, ...updateTitles }) =>
//   api
//     .put(
//       `maritalstatus`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "maritalstatus",
//         MaritalStatusId: id,
//         ...updateTitles,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

//ABC List
// export const getABClist77 = () =>
//   api
//     .post("abc", { ...body, action: "getABCList", menukey: "abc" }, options)
//     .then((res) => {
//       return res.data.datalist;
//     });
// export const geABC = (id) =>
//   api.post(
//     `abc`,
//     { ...body, action: "geABC", menukey: "abc", AbcId: id },
//     options
//   );
// export const saveABC = (getabc1) =>
//   api
//     .post(
//       `abc`,
//       { ...body, action: "dataInsert", menukey: "abc", ...getabc1 },
//       options
//     )
//     .then((res) => res.data);

// export const deleteABC = (id) => api.post(`abc`, id, options);

// export const updateABC = ({ id, ...updateabcsd }) =>
//   api
//     .put(
//       `abc`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "abc",
//         AbcId: id,
//         ...updateabcsd,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// Post Entry Api
// export const getPostEntrys = () =>
//   api
//     .post(
//       "postentry",
//       { ...body, action: "getDataList", menukey: "post-entry" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });

// export const getPostEntry = (id) =>
//   api.post(
//     `postentry`,
//     { ...body, action: "getPostEntry", menukey: "post-entry", PostId: id },
//     options
//   );

// export const updatePostEntry = ({ id, ...updatedPostEntry }) =>
//   api
//     .put(
//       `postentry`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "post-entry",
//         PostId: id,
//         ...updatedPostEntry,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const savePostEntry = (postentryData) =>
//   api
//     .post(
//       `postentry`,
//       {
//         ...body,
//         action: "dataInsert",
//         menukey: "post-entry",
//         ...postentryData,
//       },
//       options
//     )
//     .then((res) => res.data);

// export const deletePostEntry = (id) => api.post(`postentry`, id, options);

//Specialisation List Api
// export const getSpecialisation = () =>
//   api
//     .post(
//       "comboscript",
//       { ...body, action: "getSpecialisationList", menukey: "" },
//       options
//     )
//     .then((res) => {
//       return res.data;
//     });

// // Specialisation Entry Api
// export const getSpecialisations = () =>
//   api
//     .post(
//       "specialisation",
//       { ...body, action: "getDataList", menukey: "specialisation" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });

// export const getSpecialisationData = (id) =>
//   api.post(
//     `specialisation`,
//     {
//       ...body,
//       action: "getSpecialisationData",
//       menukey: "specialisation",
//       SpecialisationId: id,
//     },
//     options
//   );

// export const updateSpecialisation = ({ id, ...updatedSpecialisation }) =>
//   api
//     .put(
//       `specialisation`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "specialisation",
//         SpecialisationId: id,
//         ...updatedSpecialisation,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const saveSpecialisation = (specialisationData) =>
//   api
//     .post(
//       `specialisation`,
//       {
//         ...body,
//         action: "dataInsert",
//         menukey: "specialisation",
//         ...specialisationData,
//       },
//       options
//     )
//     .then((res) => res.data);

// export const deleteSpecialisation = (id) =>
//   api.post(`specialisation`, id, options);

// Icd Entry Api
// export const getIcds = () =>
//   api
//     .post("icd", { ...body, action: "getDataList", menukey: "icd" }, options)
//     .then((res) => {
//       return res.data.datalist;
//     });

// export const getIcdData = (id) =>
//   api.post(
//     `icd`,
//     { ...body, action: "getIcdData", menukey: "icd", ICDCode: id },
//     options
//   );

// export const updateIcd = ({ id, ...updatedIcd }) =>
//   api
//     .put(
//       `icd`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "icd",
//         ICDCode: id,
//         ...updatedIcd,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const saveIcd = (icdData) =>
//   api
//     .post(
//       `icd`,
//       { ...body, action: "dataInsert", menukey: "icd", ...icdData },
//       options
//     )
//     .then((res) => res.data);

// export const deleteIcd = (id) =>
//   api.post(
//     `icd`,
//     { ...body, action: "dataDalete", menukey: "icd", ICDCode: id },
//     options
//   );

// Adjustment Type Api
export const getAdjustmentTypes = () =>
  api
    .post(
      "adjustmentType",
      {
        ...body,
        action: "getDataList",
        menukey: "adjustment-type",
        UseFor: UseFor,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getAdjustmentType = (id) =>
  api.post(
    `adjustmentType`,
    {
      ...body,
      action: "getAdjustmentType",
      menukey: "adjustment-type",
      AdjTypeId: id,
    },
    options
  );

export const updateAdjustmentType = ({ id, ...updatedAdjustmentType }) =>
  api
    .put(
      `adjustmentType`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "adjustment-type",
        AdjTypeId: id,
        ...updatedAdjustmentType,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveAdjustmentType = (adjustmentTypeData) =>
  api
    .post(
      `adjustmentType`,
      {
        ...body,
        action: "dataInsert",
        menukey: "adjustment-type",
        ...adjustmentTypeData,
      },
      options
    )
    .then((res) => res.data);

export const deleteAdjustmentType = (id) =>
  api.post(`adjustmentType`, id, options);

// Language Preference Api
// export const getLanguagePreferences = () =>
//   api
//     .post(
//       "languagePreference",
//       { ...body, action: "getDataList", menukey: "language-preference" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });

// export const getLanguagePreferenceData = (id) =>
//   api.post(
//     `languagePreference`,
//     {
//       ...body,
//       action: "getLanguagePreferenceData",
//       menukey: "language-preference",
//       Id: id,
//     },
//     options
//   );

// export const updateLanguagePreference = ({
//   id,
//   ...updatedLanguagePreference
// }) =>
//   api
//     .put(
//       `languagePreference`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "language-preference",
//         Id: id,
//         ...updatedLanguagePreference,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const saveLanguagePreference = (languagePreferenceData) =>
//   api
//     .post(
//       `languagePreference`,
//       {
//         ...body,
//         action: "dataInsert",
//         menukey: "language-preference",
//         ...languagePreferenceData,
//       },
//       options
//     )
//     .then((res) => res.data);

// export const deleteLanguagePreference = (id) =>
//   api.post(`languagePreference`, id, options);

// Pack Size Api
// export const getPackSizes = () =>
//   api
//     .post(
//       "packSize",
//       { ...body, action: "getDataList", menukey: "pack-size" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });

// export const getPackSizeData = (id) =>
//   api.post(
//     `packSize`,
//     {
//       ...body,
//       action: "getPackSizeData",
//       menukey: "pack-size",
//       PacksizeId: id,
//     },
//     options
//   );

// export const updatePackSize = ({ id, ...updatedPackSize }) =>
//   api
//     .put(
//       `packSize`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "pack-size",
//         PacksizeId: id,
//         ...updatedPackSize,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const savePackSize = (packSizeData) =>
//   api
//     .post(
//       `packSize`,
//       { ...body, action: "dataInsert", menukey: "pack-size", ...packSizeData },
//       options
//     )
//     .then((res) => res.data);

// export const deletePackSize = (id) => api.post(`packSize`, id, options);

// Form Api
// export const getForms = () =>
//   api
//     .post("form", { ...body, action: "getDataList", menukey: "form" }, options)
//     .then((res) => {
//       return res.data.datalist;
//     });

// export const getFormData = (id) =>
//   api.post(
//     `form`,
//     { ...body, action: "getFormData", menukey: "form", DosageFormId: id },
//     options
//   );

// export const updateForm = ({ id, ...updatedForm }) =>
//   api
//     .put(
//       `form`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "form",
//         DosageFormId: id,
//         ...updatedForm,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const saveForm = (formData) =>
//   api
//     .post(
//       `form`,
//       { ...body, action: "dataInsert", menukey: "form", ...formData },
//       options
//     )
//     .then((res) => res.data);

// export const deleteForm = (id) => api.post(`form`, id, options);

// Strength Api
// export const getStrengths = () =>
//   api
//     .post(
//       "strength",
//       { ...body, action: "getDataList", menukey: "strength" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });

// export const getStrengthData = (id) =>
//   api.post(
//     `strength`,
//     { ...body, action: "getStrengthData", menukey: "strength", StrengthId: id },
//     options
//   );

// export const updateStrength = ({ id, ...updatedStrength }) =>
//   api
//     .put(
//       `strength`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "strength",
//         StrengthId: id,
//         ...updatedStrength,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const saveStrength = (strengthData) =>
//   api
//     .post(
//       `strength`,
//       { ...body, action: "dataInsert", menukey: "strength", ...strengthData },
//       options
//     )
//     .then((res) => res.data);

// export const deleteStrength = (id) => api.post(`strength`, id, options);

//Dispensing Language List Api
export const getLoginDispensingLanguage = async (options) =>
  api
    .post(
      "comboscript",
      { action: "getDispensingLanguageList", menukey: "" },
      options
    )
    .then((res) => {
      return res.data;
    });
export const getDispensingLanguage = () =>
  api
    .post(
      "comboscript",
      { action: "getDispensingLanguageList", menukey: "" },
      options
    )
    .then((res) => {
      return res.data;
    });

// Ui Language Api

export const updateUiLanguage = (params) =>
  //console.log(params.id);
  api
    .put(
      `uiLanguage`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "ui-language",
        LangLabelId: params.id,
        LangText: params.LangText,
        
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getUiLanguages = (params) =>
  api.post("uiLanguage", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getUiLanguageData = (id) =>
  api.post(
    `uiLanguage`,
    {
      ...body,
      action: "getUiLanguageData",
      menukey: "ui-language",
      LangLabelId: id,
    },
    options
  );

export const saveUiLanguage = (uiLanguageData) =>
  api
    .post(
      `uiLanguage`,
      {
        ...body,
        action: "dataInsert",
        menukey: "ui-language",
        ...uiLanguageData,
      },
      options
    )
    .then((res) => res.data);

// Facility Api api.js

export const updateFacility = (params) =>
  //console.log(params.id);
  api
    .put(
      `facility`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "facility",
        FacilityId: params.id,
        bDispense: params.bDispenseVal,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getFacilityCode = (params) =>
  api.post("facility", { ...body, params }, options).then((res) => {
    return res.data.datalist;
  });

export const deleteaFacility = (id) => api.post(`facility`, id, options);

export const getFacilities = (params) =>
  api.post("facility", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveFacility = (saveFacilityData) =>
  api
    .post(
      `facility`,
      {
        ...body,
        action: "dataInsert",
        menukey: "facility",
        ...saveFacilityData,
      },
      options
    )
    .then((res) => res.data);

export const updateSingleFacility = ({ id, ...updatedFacility }) =>
  api
    .put(
      `facility`,
      {
        ...body,
        action: "UpdateFacility",
        UserName: UserName,
        lan: lan,
        menukey: "facility",
        FacilityId: id,
        ...updatedFacility,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getFacilitiesList = (params) =>
  api.post("facility_list", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getSingleListView = (id) =>
  api.post(
    `facility_list_single_view`,
    {
      ...body,
      action: "getFacilitySingleView",
      UserName: UserName,
      lan: lan,
      menukey: "facility-list",
      FacilityId: id,
    },
    options
  );

export const getFacilitySingleView = (id) =>
  api.post(
    `facility`,
    {
      ...body,
      action: "getFacilitySingleView",
      UserName: UserName,
      lan: lan,
      menukey: "facility",
      FacilityId: id,
    },
    options
  );

export const gZonelist = (zoneData) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...zoneData }, options).then((res) => {
    return res;
  });
};

export const gCommunelist = (communeData) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...communeData }, options).then((res) => {
    return res;
  });
};

export const getPatientTotal = (params) =>
  api.post("patients", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Role Entry from
export const getRole = () =>
  api
    .post("roles", { ...body, action: "getRoleList", menukey: "role" }, options)
    .then((res) => {
      return res.data.datalist;
    });
export const geRoles = (id) =>
  api.post(
    `roles`,
    { ...body, action: "geRole", menukey: "role", id: id },
    options
  );
export const saveRole = (getrole1) =>
  api
    .post(
      `roles`,
      { ...body, action: "dataInsert", menukey: "role", ...getrole1 },
      options
    )
    .then((res) => res.data);

//export const deleteRole = (id) =>  api.post(`roles`,{...body, "action": "dataDalete", "menukey": "role", "id":id}, options)
export const deleteRole = (id) => api.post(`roles`, id, options);
export const updateRole = ({ id, ...updateRoles }) =>
  api
    .put(
      `roles`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "role",
        id: id,
        ...updateRoles,
      },
      options
    )
    .then((res) => {
      return res;
    });

// User Entry Api
export const getUserList = () =>
  api
    .post(
      "userlist",
      { ...body, action: "getUserList", menukey: "user-entry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getUser = (id) =>
  api.post(
    `userlist`,
    { ...body, action: "getUser", menukey: "user-entry", id: id },
    options
  );

export const updateUser = ({ id, ...updatedUser }) =>
  api
    .put(
      `userlist`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "user-entry",
        user_id: id,
        ...updatedUser,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveUser = (userData) =>
  api
    .post(
      `userlist`,
      { ...body, action: "dataInsert", menukey: "user-entry", ...userData },
      options
    )
    .then((res) => res.data);
export const userDelete = (id) => api.post(`userlist`, id, options);
// export const userDelete = (id) =>
//   api.post(
//     `userlist`,
//     { ...body, action: "userDelete", menukey: "user-entry", user_id: id },
//     options
//   );

//role permission

export const getRoleAccessEntry = () =>
  api
    .post(
      "roleaccess",
      { ...body, action: "getRoleList", menukey: "roleaccess" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getMenuPerEntry = (params) =>
  api.post("roleaccess", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const rolesToMenuInsert = (params) =>
  //console.log(params.id);
  api
    .post(
      `roleaccess`,
      {
        ...body,
        action: "dataInsert",
        UserName: UserName,
        lan: lan,
        menukey: "role-access-entry",
        role_id: params.selectedRoleData,
        menu_id: params.menu_id,
        bChecked: params.bCheckedVal,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const rolesToMenuInsertUpdateDelete = (params,pProps) =>
  //console.log(params.id);
  api
    .post(
      `roleaccess`,
      {
        ...body,
        action: "dataInsertUpdateDelete",
        UserName: UserName,
        lan: lan,
        menukey: "role-access-entry",
        role_id: params.selectedRoleData,
        menu_id: params.menu_id,
        seteditpermission: params.seteditpermission,
      },
      options
    )
    .then((res) => {
      // console.log('res: ', res.data);
      // console.log(params);
      // console.log("pProps: ",pProps);

      // if (res.data.status == 200) {

      //   pProps.openNoticeModal({
      //     isOpen: true,
      //     msg: res.data.message,
      //     msgtype: res.data.success,
      //   });
    
    
      //   }else{   
      //     pProps.openNoticeModal({
      //       isOpen: true,
      //       msg: res.data.message,
      //       msgtype: res.data.success,
      //     });
      // }

      return res;
    });



//Month Close Api
export const monthCloseUpdate = (params) => {
  api
    .post(
      `monthclose`,
      {
        ...body,
        action: "dataInsert",
        menukey: "month-close",
        currYearId: params.currYearId,
        currMonthId: params.currMonthId,
        currFacilityId: params.currFacilityId,
      },
      options
    )
    .then((res) => {
      return res;
    });
};

export const getMenuShortOrder = (params) =>
  api.post("roleaccess", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const updateMenuShortOrder = (params) =>
  //console.log(params.id);
  api
    .put(
      `roleaccess`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: lan,
        menukey: "role-access-entry",
        MenuData: params.MenuData,
      },
      options
    )
    .then((res) => {
      return res;
    });

// Generics Api
export const getGenericss = () =>
  api
    .post(
      "generics",
      { ...body, action: "getDataList", menukey: "generics" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getGenericsData = (id) =>
  api.post(
    `generics`,
    { ...body, action: "getGenericsData", menukey: "generics", GenericId: id },
    options
  );

export const updateGenerics = ({ id, ...updatedGenerics }) =>
  api
    .put(
      `generics`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "generics",
        GenericId: id,
        ...updatedGenerics,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveGenerics = (genericsData) =>
  api
    .post(
      `generics`,
      { ...body, action: "dataInsert", menukey: "generics", ...genericsData },
      options
    )
    .then((res) => res.data);

//export const deleteGenerics = (id)=>  api.post(`generics`,{...body, "action": "dataDalete", "menukey": "generics", "GenericId":id}, options)
export const deleteGenerics = (id) => api.post(`generics`, id, options);

//Patient Import
// export const savePatientImport = (PatientImportData) =>
//   api
//     .post(
//       `patient_import`,
//       {
//         action: "PatientImportDataInsert",
//         menukey: "patient-import",
//         ...PatientImportData,
//       },
//       options
//     )
//     .then((res) => res.data);

// MOS Range Entry Api
export const getMOSRanges = () =>
  api
    .post(
      "mosrange",
      { ...body, action: "getDataList", menukey: "mos-range" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getMOSRange = (id) =>
  api.post(
    `mosrange`,
    { ...body, action: "getMOSRange", menukey: "mos-range", MosTypeId: id },
    options
  );

export const updateMOSRange = ({ id, ...updatedMOSRange }) =>
  api
    .put(
      `mosrange`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "mos-range",
        MosTypeId: id,
        ...updatedMOSRange,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveMOSRange = (mosrangeData) =>
  api
    .post(
      `mosrange`,
      { ...body, action: "dataInsert", menukey: "mos-range", ...mosrangeData },
      options
    )
    .then((res) => res.data);

//export const deleteMOSRange = (id)=>  api.post(`mosrange`,{...body, "action": "dataDalete", "menukey": "mos-range", "MosTypeId":id}, options)
export const deleteMOSRange = (id) => api.post(`mosrange`, id, options);

//Location List
// export const getLocationsList = () =>
//   api
//     .post(
//       "location",
//       { ...body, action: "getLocation", menukey: "location" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// export const getLocations = (id) =>
//   api.post(
//     `location`,
//     { ...body, action: "getLocationsid", menukey: "location", LocationId: id },
//     options
//   );
// export const SaveLocation = (getLocation1) =>
//   api
//     .post(
//       `location`,
//       { ...body, action: "dataInsert", menukey: "location", ...getLocation1 },
//       options
//     )
//     .then((res) => res.data);

// export const DeleteLocation = (id) => api.post(`location`, id, options);

// export const UpdateLocations = ({ id, ...UpdateLocation }) =>
//   api
//     .put(
//       `location`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "location",
//         LocationId: id,
//         ...UpdateLocation,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

//menu Insert
export const getmenuEntry = () =>
  api
    .post(
      "menuentry",
      { ...body, action: "getMenuList", menukey: "menuentry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const saveMenuentry = (userData) =>
  api
    .post(
      `menuentry`,
      { ...body, action: "dataInsert", menukey: "menuentry", ...userData },
      options
    )
    .then((res) => res.data);

export const updateMenuentry = ({ id, ...updatedMenu }) =>
  api
    .put(
      `menuentry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "menuentry",
        id: id,
        ...updatedMenu,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const deletemenu = (id) => api.post(`menuentry`, id, options);

export const geImenuentrys = (id) =>
  api.post(
    `menuentry`,
    { ...body, action: "gemenuEntry", menukey: "menuentry", id: id },
    options
  );

//ATC List
// export const getatcEntry = () =>
//   api
//     .post("atc", { ...body, action: "getATCList", menukey: "atc" }, options)
//     .then((res) => {
//       return res.data.datalist;
//     });
// export const geAtc = (id) =>
//   api.post(
//     `atc`,
//     { ...body, action: "geATC", menukey: "atc", ATC: id },
//     options
//   );
// export const saveATC = (getatc1) =>
//   api
//     .post(
//       `atc`,
//       { ...body, action: "dataInsert", menukey: "atc", ...getatc1 },
//       options
//     )
//     .then((res) => res.data);

// export const deleteATC = (id) => api.post(`atc`, id, options);

// export const updateATC = ({ id, ...updateATCed }) =>
//   api
//     .put(
//       `atc`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "atc",
//         ATC: id,
//         ...updateATCed,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

//Source Entry
// export const getsourceEntry = () =>
//   api
//     .post(
//       "source",
//       { ...body, action: "getSourceList", menukey: "source" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// export const geSource = (id) =>
//   api.post(
//     `source`,
//     { ...body, action: "geSource", menukey: "source", SourceId: id },
//     options
//   );
// export const saveSource = (getSource1) =>
//   api
//     .post(
//       `source`,
//       { ...body, action: "dataInsert", menukey: "source", ...getSource1 },
//       options
//     )
//     .then((res) => res.data);

// export const deletesource = (id) => api.post(`source`, id, options);

// export const updatesource = ({ id, ...updatesourceed }) =>
//   api
//     .put(
//       `source`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "source",
//         SourceId: id,
//         ...updatesourceed,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const getStockSummaryChart = (params) => {
//   return api.post("StockSummary", { params }, options).then((res) => {
//     return res;
//   });
// };

// export const getStockSummaryTable = (params) => {
//   return api.post("StockSummary", { params }, options).then((res) => {
//     return res.data.datalist;
//   });
// };

// export const getConsumptionTrendsForTheTopFiveProductsChart = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0,
//   menukey
// ) => {
//   return api
//     .post(
//       "ConsumptionTrendsForTheTopFiveProducts",
//       {
//         ...body,
//         action: "getConsumptionTrendsForTheTopFiveProductsChart",
//         menukey: menukey,
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getConsumptionTrendsForTheTopFiveProductsTable = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0,
//   menukey
// ) => {
//   return api
//     .post(
//       "ConsumptionTrendsForTheTopFiveProducts",
//       {
//         ...body,
//         action: "getConsumptionTrendsForTheTopFiveProductsTable",
//         menukey: menukey,
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

export const getStockStatusDetailsChart = (params) => {
  return api.post("StockStatusDetails", { params }, options).then((res) => {
    return res;
  });
};

// export const getAMCTrendsforTheTopFiveProductsChart = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0,
//   menukey
// ) => {
//   return api
//     .post(
//       "AMCTrendsforTheTopFiveProducts",
//       {
//         ...body,
//         action: "getAMCTrendsforTheTopFiveProductsChart",
//         menukey: menukey,
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getAMCTrendsforTheTopFiveProductsTable = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0,
//   menukey
// ) => {
//   return api
//     .post(
//       "AMCTrendsforTheTopFiveProducts",
//       {
//         ...body,
//         action: "getAMCTrendsforTheTopFiveProductsTable",
//         menukey: menukey,
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getPercentageOfFacilitiesWithAStockoutOfTracerCommoditiesChart = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0,
//   menukey
// ) => {
//   return api
//     .post(
//       "PercentageOfFacilitiesWithAStockoutOfTracerCommodities",
//       {
//         ...body,
//         action:
//           "getPercentageOfFacilitiesWithAStockoutOfTracerCommoditiesChart",
//         menukey: menukey,
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getPercentageOfFacilitiesWithAStockoutOfTracerCommoditiesTable = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0,
//   menukey
// ) => {
//   return api
//     .post(
//       "PercentageOfFacilitiesWithAStockoutOfTracerCommodities",
//       {
//         ...body,
//         action:
//           "getPercentageOfFacilitiesWithAStockoutOfTracerCommoditiesTable",
//         menukey: menukey,
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getAvailabilityOfCommoditiesChart = (params) => {
//   return api
//     .post("AvailabilityOfCommodities", { params }, options)
//     .then((res) => {
//       return res;
//     });
// };

//User profile

export const UserProfileupdate = ({ id, ...updatedUser }) =>
  api
    .put(
      `Myprofile`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "user-entry",
        user_id: id,
        UserName: UserName,
        lan: lan,
        ...updatedUser,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getUserProfile = (id) =>
  api.post(
    `Myprofile`,
    { ...body, action: "getUser", menukey: "user-entry", id: id },
    options
  );

//Adult Patients by Protocol

// export const getAdultPatientsByProtocolChart = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   return api
//     .post(
//       "AdultPatientsByProtocol",
//       {
//         ...body,
//         action: "getAdultPatientsByProtocolChart",
//         menukey: "adult-patients-by-protocol",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getAdultPatientsByProtocolTable = (params) => {
//   return api
//     .post("AdultPatientsByProtocol", { params }, options)
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

//Paediatric Patients by Protocol

// export const getPaediatricPatientsByProtocolChart = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   return api
//     .post(
//       "PaediatricPatientsByProtocol",
//       {
//         ...body,
//         action: "getPaediatricPatientsByProtocolChart",
//         menukey: "Patient Dashboard",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getPaediatricPatientsByProtocolTable = (params) => {
//   return api
//     .post("PaediatricPatientsByProtocol", { params }, options)
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

//Trend of Active Patients

// export const getTrendofActivePatientsChart = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   return api
//     .post(
//       "TrendofActivePatients",
//       {
//         ...body,
//         action: "getTrendofActivePatientsChart",
//         menukey: "trend-of-active-patients",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getTrendofActivePatientsTable = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0,
//   menukey
// ) => {
//   return api
//     .post(
//       "TrendofActivePatients",
//       {
//         ...body,
//         action: "getTrendofActivePatientsTable",
//         menukey: menukey,
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

//TLD Uptake
// export const getTLDUptakePatientsTrendChart = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   return api
//     .post(
//       "TldUptake",
//       {
//         ...body,
//         action: "getTLDUptakePatientsTrendChart",
//         menukey: "tld-uptake",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getTLDUptakePatientsTrendTable = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0,
//   menukey
// ) => {
//   return api
//     .post(
//       "TldUptake",
//       {
//         ...body,
//         action: "getTLDUptakePatientsTrendTable",
//         menukey: menukey,
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

//TLD Transition Trend
// export const getTLDTransitionPatientsTrendChart = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   return api
//     .post(
//       "TldTransitionTrend",
//       {
//         ...body,
//         action: "getTLDTransitionPatientsTrendChart",
//         menukey: "tld-transition-trend",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getTLDTransitionPatientsTrendTable = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0,
//   menukey
// ) => {
//   return api
//     .post(
//       "TldTransitionTrend",
//       {
//         ...body,
//         action: "getTLDTransitionPatientsTrendTable",
//         menukey: menukey,
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

//MMD Among Adults (+15 years
// export const getMMDAmongAdultPatientsTrendChart = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   return api
//     .post(
//       "MmdAmongAdults",
//       {
//         ...body,
//         action: "getMMDAmongAdultPatientsTrendChart",
//         menukey: "mmd-among-adults",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getMMDAmongAdultPatientsTrendTable = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0,
//   menukey
// ) => {
//   return api
//     .post(
//       "MmdAmongAdults",
//       {
//         ...body,
//         action: "getMMDAmongAdultPatientsTrendTable",
//         menukey: menukey,
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

//MMD Coverage by site
// export const getMMDCoveragePatientsTrendChart = (
//   pYearId,
//   pMonthId,
//   pFacilityId = 0
// ) => {
//   return api
//     .post(
//       "MmdCoverageBysite",
//       {
//         ...body,
//         action: "getMMDCoveragePatientsTrendChart",
//         menukey: "mmd-coverage-by-site",
//         FacilityId: pFacilityId,
//         YearId: pYearId,
//         MonthId: pMonthId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

// export const getMMDCoveragePatientsTrendTable = (params) => {
//   return api.post("MmdCoverageBysite", { params }, options).then((res) => {
//     return res.data.datalist;
//   });
// };

// export const getAvailabilityOfCommoditiesTable = (params) => {
//   return api
//     .post("AvailabilityOfCommodities", { params }, options)
//     .then((res) => {
//       return res.data.datalist;
//     });
// };

export const getStockStatusDetailsTable = (
  pYearId,
  pMonthId,
  pFacilityId = 0,
  menukey
) => {
  return api
    .post(
      "StockStatusDetails",
      {
        ...body,
        action: "getStockStatusDetailsTable",
        menukey: menukey,
        FacilityId: pFacilityId,
        YearId: pYearId,
        MonthId: pMonthId,
      },
      options
    )
    .then((res) => {
      // return res.data.datalist;
      return res.data.datalist.data;
    });
};

//Regimen
// export const getRegimenList = () =>
//   api
//     .post(
//       "Regimen",
//       { ...body, action: "getRegimenList", menukey: "regimen" },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });

// export const getRegimen = (id) =>
//   api.post(
//     `Regimen`,
//     { ...body, action: "getRegimen", menukey: "regimen", RegimenId: id },
//     options
//   );

// export const saveRegimen = (formData) =>
//   api
//     .post(
//       `Regimen`,
//       { ...body, action: "dataInsert", menukey: "regimen", ...formData },
//       options
//     )
//     .then((res) => res.data);

// export const updateRegimen = ({ id, ...formData }) =>
//   api
//     .put(
//       `Regimen`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "regimen",
//         RegimenId: id,
//         ...formData,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// export const deleteRegimen = (id) => api.post(`Regimen`, id, options);

//Supplier
export const getSupplierInvs = (params) =>
  api.post("supplier_invoice_list", { params }, options).then((res) => {
    return res.data.receiveinvdata;
  });

export const saveSupplierInv = (formData) =>
  api.post(`supplierSave`, formData, options).then((res) => res.data);

export const updateSupplierInv = ({ id, ...formData }) =>
  api
    .put(
      `supplierUpdate`,
      { ...body, menukey: "receive-from-supplier", ...formData },
      options
    )
    .then((res) => {
      return res.data;
    });

//Receive From Supplier

export const getReceiveFromSupplierList = (params) =>
  api.post("ReceiveFromSupplier", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const ReceiveFromSupplierDataInsert = (formData) =>
  api
    .post(
      `ReceiveFromSupplier`,
      {
        ...body,
        action: "ReceiveFromSupplierDataInsert",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const ReceiveFromSupplierDataUpdate = ({ id, ...formData }) =>
  api
    .put(
      `ReceiveFromSupplier`,
      {
        ...body,
        action: "ReceiveFromSupplierDataUpdate",
        menukey: "receive-from-supplier",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const ReceiveFromSupplierUnPost = ({ id, ...formData }) =>
  api
    .put(
      `ReceiveFromSupplier`,
      {
        ...body,
        action: "ReceiveFromSupplierUnPost",
        menukey: "receive-from-supplier",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getSupplierInv = (id) =>
  api.post(
    `ReceiveFromSupplier`,
    {
      ...body,
      action: "getReceiveFromSupplierListSingle",
      menukey: "receive-from-supplier",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const ReceiveFromSupplierDataDalete = (id) =>
  api
    .post(
      `ReceiveFromSupplier`,
      {
        ...body,
        action: "ReceiveFromSupplierDataDalete",
        menukey: "receive-from-supplier",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getContractList = () =>
  api
    .post(
      "ReceiveFromSupplier",
      { ...body, action: "getContractList", menukey: "receive-from-supplier" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const postReceiveFromSupplier = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "receive-from-supplier",
        FacilityId: FacilityId,
        TableName: "recinv",
        TransactionId: id,
        TransactionTypeId: 1,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });

//End of Receive From Supplier

//Adjustment Invoice
export const getAdjustmentInvoiceList = (params) =>
  api.post("AdjustmentInvoice", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getAdjustmentInvoiceListSingle = (id) =>
  api.post(
    `AdjustmentInvoice`,
    {
      ...body,
      action: "getAdjustmentInvoiceListSingle",
      menukey: "adjustment",
      TransactionId: id,
      FacilityId: FacilityId,
      UseFor: UseFor,
    },
    options
  );

export const AdjustmentInvoiceDataInsert = (formData) =>
  api
    .post(
      `AdjustmentInvoice`,
      {
        ...body,
        action: "AdjustmentInvoiceDataInsert",
        menukey: "adjustment",
        UserName: UserName,
        lan: lan,
        UseFor: UseFor,
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const AdjustmentInvoiceDataUpdate = ({ id, ...formData }) =>
  api
    .put(
      `AdjustmentInvoice`,
      {
        ...body,
        action: "AdjustmentInvoiceDataUpdate",
        menukey: "adjustment",
        menukeyOrigin: "adjustment",
        UserName: UserName,
        lan: lan,
        UseFor: UseFor,
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });


  export const AdjustmentApprovalInvoiceDataUpdate = ({ id, ...formData }) =>
    api
      .put(
        `AdjustmentInvoice`,
        {
          ...body,
          action: "AdjustmentInvoiceDataUpdate",
          menukey: "adjustment",
          menukeyOrigin: "adjustment-approval",
          UserName: UserName,
          lan: lan,
          UseFor: UseFor,
          ...formData,
        },
        options
      )
      .then((res) => {
        return res.data;
      });

export const AdjustmentInvoiceDataUpdateForEntry = ({ id, ...formData }) =>
    api
      .put(
        `AdjustmentInvoice`,
        {
          ...body,
          action: "AdjustmentInvoiceDataUpdateForEntry",
          menukey: "adjustment",
          UserName: UserName,
          lan: lan,
          UseFor: UseFor,
          ...formData,
        },
        options
      )
      .then((res) => {
        return res.data;
      });    

export const AdjustmentInvoiceDataDalete = (id) =>
  api
    .post(
      `AdjustmentInvoice`,
      {
        ...body,
        action: "AdjustmentInvoiceDataDalete",
        menukey: "adjustment",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

    export const getAdjustmentInvoiceListApproval = (params) =>
    api.post("AdjustmentInvoice", { params }, options).then((res) => {
      return res.data.datalist;
    });  
  
  
  
  export const getAdjustmentInvoiceListSingleForEntry = (id) =>
    api.post(
      `AdjustmentInvoice`,
      {
        ...body,
        action: "getAdjustmentInvoiceListSingleForEntry",
        menukey: "adjustment",
        TransactionId: id,
        FacilityId: FacilityId,
        UseFor: UseFor,
      },
      options
  );    

export const updateAdjustmentInvoiceStock = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "adjustment",
        FacilityId: FacilityId,
        TableName: "adjinv",
        TransactionId: id,
        TransactionTypeId: 0,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });


    export const SubmitAdjustmentInvoice = ({id, ...formData}) =>
  api
    .post(
      `AdjustmentInvoice`,
      {
        ...body,
        action: "SubmitAdjustmentInvoice",
        menukey: "adjustment",
        FacilityId: FacilityId,       
        UserName: UserName,
        lan: lan,
        ...formData
      },
      options
    )
    .then((res) => {
      return res.data;
    });

    export const UnApproveAdjustmentInvoice = ({id, ...formData}) =>
  api
    .post(
      `AdjustmentInvoice`,
      {
        ...body,
        action: "UnSubmitAdjustmentInvoice",
        menukey: "adjustment-approval",
        FacilityId: FacilityId,       
        UserName: UserName,
        lan: lan,
        ...formData
      },
      options
    )
    .then((res) => {
      return res.data;
    });

//End of Adjustment Invoice

//Start of Adjustment Facility Invoice
export const getAdjustmentFacilityInvoiceList = (params) =>
  api.post("AdjustmentFacilityInvoice", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getAdjustmentFacilityInvoiceListSingle = (id) =>
  api.post(
    `AdjustmentFacilityInvoice`,
    {
      ...body,
      action: "getAdjustmentFacilityInvoiceListSingle",
      menukey: "adjustment",
      TransactionId: id,
      FacilityId: FacilityId,
      UseFor: UseFor,
    },
    options
  );  
  
export const AdjustmentFacilityInvoiceDataInsert = (formData) =>
  api
    .post(
      `AdjustmentFacilityInvoice`,
      {
        ...body,
        action: "AdjustmentFacilityInvoiceDataInsert",
        menukey: "adjustment-facility",
        UserName: UserName,
        lan: lan,
        UseFor: UseFor,
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const AdjustmentFacilityInvoiceDataUpdate = ({ id, ...formData }) =>
  api
    .put(
      `AdjustmentFacilityInvoice`,
      {
        ...body,
        action: "AdjustmentFacilityInvoiceDataUpdate",
        menukey: "adjustment-facility",
        menukeyOrigin: "adjustment-facility",
        UserName: UserName,
        lan: lan,
        UseFor: UseFor,
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

    export const AdjustmentFacilityApprovalInvoiceDataUpdate = ({ id, ...formData }) =>
      api
        .put(
          `AdjustmentFacilityInvoice`,
          {
            ...body,
            action: "AdjustmentFacilityInvoiceDataUpdate",
            menukey: "adjustment-facility",
            menukeyOrigin: "adjustment-facility-approval",
            UserName: UserName,
            lan: lan,
            UseFor: UseFor,
            ...formData,
          },
          options
        )
        .then((res) => {
          return res.data;
        });
    

export const AdjustmentFacilityInvoiceDataUpdateForEntry = ({ id, ...formData }) =>
    api
      .put(
        `AdjustmentFacilityInvoice`,
        {
          ...body,
          action: "AdjustmentFacilityInvoiceDataUpdateForEntry",
          menukey: "adjustment-facility",
          UserName: UserName,
          lan: lan,
          UseFor: UseFor,
          ...formData,
        },
        options
      )
      .then((res) => {
        return res.data;
      });    

export const AdjustmentFacilityInvoiceDataDalete = (id) =>
  api
    .post(
      `AdjustmentFacilityInvoice`,
      {
        ...body,
        action: "AdjustmentFacilityInvoiceDataDalete",
        menukey: "adjustment-facility",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

 export const getAdjustmentFacilityInvoiceListApproval = (params) =>
    api.post("AdjustmentFacilityInvoice", { params }, options).then((res) => {
      return res.data.datalist;
    });  
  	
	
  export const getAdjustmentFacilityInvoiceListSingleForEntry = (id) =>
    api.post(
      `AdjustmentFacilityInvoice`,
      {
        ...body,
        action: "getAdjustmentFacilityInvoiceListSingleForEntry",
        menukey: "adjustment-facility",
        TransactionId: id,
        FacilityId: FacilityId,
        UseFor: UseFor,
      },
      options
  );

export const updateAdjustmentFacilityInvoiceStock = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "adjustment-facility",
        FacilityId: FacilityId,
        TableName: "adjinv",
        TransactionId: id,
        TransactionTypeId: 0,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

    export const SubmitAdjustmentFacilityInvoice = ({id, ...formData}) =>
  api
    .post(
      `AdjustmentFacilityInvoice`,
      {
        ...body,
        action: "SubmitAdjustmentFacilityInvoice",
        menukey: "adjustment-facility",
        FacilityId: FacilityId,       
        UserName: UserName,
        lan: lan,
        ...formData
      },
      options
    )
    .then((res) => {
      return res.data;
    });
	
    export const UnApproveAdjustmentFacilityInvoice = ({id, ...formData}) => api.post(
      `AdjustmentFacilityInvoice`,
      {
        ...body,
        action: "UnSubmitAdjustmentFacilityInvoice",
        menukey: "adjustment-facility-approval",
        FacilityId: FacilityId,       
        UserName: UserName,
        lan: lan,
        ...formData
      },
      options
    )
    .then((res) => {
      return res.data;
    });
	
//End Of Adjustment Facility Invoice

//National Supplier
export const getNationalSupplierlist = () =>
  api
    .post(
      "NationalSupplier",
      {
        ...body,
        action: "getNationalSupplierList",
        menukey: "NationalSupplier",
        UseFor: UseFor,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const geNationalSupplier = (id) =>
  api.post(
    `NationalSupplier`,
    {
      ...body,
      action: "geNationalSupplier",
      menukey: "NationalSupplier",
      SupCode: id,
      FacilityId: FacilityId,
    },
    options
  );
export const saveNationalSupplier = (getNationalSupplier1) =>
  api
    .post(
      `NationalSupplier`,
      {
        ...body,
        action: "dataInsert",
        menukey: "abc",
        UseFor: UseFor,
        ...getNationalSupplier1,
      },
      options
    )
    .then((res) => res.data);

export const deleteNationalSupplier = (id) =>
  api.post(`NationalSupplier`, id, options);

export const updateNationalSupplier = ({ id, ...updateNationalSuppliersd }) =>
  api
    .put(
      `NationalSupplier`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "NationalSupplier",
        SupCode: id,
        ...updateNationalSuppliersd,
      },
      options
    )
    .then((res) => {
      return res;
    });

//=== Gate Pass =====
export const getGatePassInvs = (params) =>
  api.post("gatePassinvoicelist", { params }, options).then((res) => {
    return res.data.receiveinvdata;
  });

export const saveGatePassInv = (dispenserData) =>
  api
    .post(
      `gatePassSave`,
      { ...body, 
        ...dispenserData, 
        UserName: UserName, 
        lan: lan },
      options
    )
    .then((res) => res.data);

export const deleteGatePassInv = (id) =>
  api
    .post(
      `gatePassDelete`,
      {
        ...body,
        menukey: "gate-pass",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getGatePassInv = (id) =>
  api.post(
    `gatePassListSingle`,
    {
      ...body,
      menukey: "gate-pass",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const updateGatePassInv = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `gatePassUpdate`,
      {
        ...body,
        menukey: "gate-pass",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getInvoiceList = () =>
  api
    .post(
      "invoices",
      {
        ...body,
        action: "getIvoices",
        menukey: "gate-pass",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.productlist;
    });

export const getNonGatePassList = () =>
  api
    .post(
      "receivedNonOrderList",
      { ...body, menukey: "receive", FacilityId: FacilityId },
      options
    )
    .then((res) => {
      return res.data.receiveinvdata;
    });

export const saveNonGatePassOrder = (id) =>
  api
    .post(
      `receivedNonReceiveOrderProducts`,
      { ...body, menukey: "receive", FacilityId: FacilityId, OrderId: id },
      options
    )
    .then((res) => {
      return res.data.receiveinvdata;
    });
//=== End Gate Pass =====

// Start Indent

export const getIndentList = (params) =>
  api.post("Indent", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const IndentDataInsert = (formData) =>
  api
    .post(
      `Indent`,
      {
        ...body,
        action: "IndentDataInsert",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const IndentDataUpdate = ({ id, ...formData }) =>
  api
    .put(
      `Indent`,
      {
        ...body,
        action: "IndentDataUpdate",
        menukey: "indent",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getIndentInv = (id) =>
  api.post(
    `Indent`,
    {
      ...body,
      action: "getIndentListSingle",
      menukey: "indent",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const IndentDataDalete = (id) =>
  api
    .post(
      `Indent`,
      {
        ...body,
        action: "IndentDataDalete",
        menukey: "indent",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getIndentContractList = () =>
  api
    .post(
      "Indent",
      { ...body, action: "getIndentContractList", menukey: "indent" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const postIndent = (id) =>
  api
    .post(
      `Indent`,
      {
        ...body,
        action: "IndentPost",
        menukey: "indent",
        FacilityId: FacilityId,
        TableName: "tblindent",
        TransactionId: id,
        TransactionTypeId: 1,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      // console.log("dddddd",res.data);
      return res;
    });

//Indendt Product Modal

export const getIndentProductsList = () =>
  api
    .post(
      "indentproducts",
      {
        ...body,
        action: "getProducts",
        menukey: "products",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.productlist;
    });

// End Indent

// Error Log
export const getErrorLogData = (params) =>
  api.post("errorlog", { params }, options).then((res) => {
    return res.data.datalist;
  });

// Audit Log

export const getAuditLogData = (params) =>
  api.post("auditlog", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getLogSingleRow = (params) =>
  api.post("auditlog", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const gDataBaseInfo = (params) =>
  api.post("auditlog", { params }, options).then((res) => {
    //console.log("dddddd",res.data.datalist);
    return res.data.datalist;
  });

export const gTableInfo = (params) =>
  api.post("auditlog", { params }, options).then((res) => {
    return res.data.datalist;
  });

// Options Form Api
export const getOptionsForms = () =>
  api
    .post(
      "optionsForm",
      { ...body, action: "getDataList", menukey: "options" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getOptionsFormData = (id) =>
  api.post(
    `optionsForm`,
    {
      ...body,
      action: "getOptionsFormData",
      menukey: "options",
      FacilityCode: id,
    },
    options
  );

export const updateOptionsForm = ({ id, ...updatedOptionsForm }) =>
  api
    .put(
      `optionsForm`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "options",
        FacilityCode: id,
        ...updatedOptionsForm,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Others Facility List from
export const getOthersFacilityList = () =>
  api
    .post(
      "othersFacilityLists",
      {
        ...body,
        action: "getOthersFacilityListList",
        menukey: "others-facility-list",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const geOthersFacilityLists = (id) =>
  api.post(
    `othersFacilityLists`,
    {
      ...body,
      action: "geOthersFacilityList",
      menukey: "others-facility-list",
      id: id,
      FacilityId: FacilityId,
    },
    options
  );
export const saveOthersFacilityList = (getothersFacilityList1) =>
  api
    .post(
      `othersFacilityLists`,
      {
        ...body,
        action: "dataInsert",
        menukey: "others-facility-list",
        FacilityId: FacilityId,
        ...getothersFacilityList1,
      },
      options
    )
    .then((res) => res.data);

export const deleteOthersFacilityList = (id) =>
  api.post(`othersFacilityLists`, id, options);
export const updateOthersFacilityList = ({
  id,
  ...updateOthersFacilityLists
}) =>
  api
    .put(
      `othersFacilityLists`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "others-facility-list",
        FacilityId: FacilityId,
        id: id,
        ...updateOthersFacilityLists,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getOthersFacilityCode = (params) =>
  api
    .post(
      "othersFacilityLists",
      { ...body, FacilityId: FacilityId, params },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

//Provisional Receive

export const getProvisionalReceiveList = (params) =>
  api.post("ProvisionalReceive", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const ProvisionalReceiveDataInsert = (formData) =>
  api
    .post(
      `ProvisionalReceive`,
      {
        ...body,
        action: "ProvisionalReceiveDataInsert",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const ProvisionalReceiveDataUpdate = ({ id, ...formData }) =>
  api
    .put(
      `ProvisionalReceive`,
      {
        ...body,
        action: "ProvisionalReceiveDataUpdate",
        menukey: "provisional-receive",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getProvisionalReceiveListSingle = (id) =>
  api.post(
    `ProvisionalReceive`,
    {
      ...body,
      action: "getProvisionalReceiveListSingle",
      menukey: "provisional-receive",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const ProvisionalReceiveDataDalete = (id) =>
  api
    .post(
      `ProvisionalReceive`,
      {
        ...body,
        action: "ProvisionalReceiveDataDalete",
        menukey: "provisional-receive",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getContractListByYear = (params) =>
  api
    .post(
      "ProvisionalReceive",
      {
        ...body,
        action: "getContractListByYear",
        menukey: "provisional-receive",
        YearId: params.queryKey[1],
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

//End of Provisional Receive


export const getProvisionalReceiveItemsList = (params) =>
  api.post("provisionalReceiveItems", { params }, options).then((res) => {
    return res.data.productlist;
  });

//Standing Board Entry

export const getStandingBoardEntryList = (params) =>
  api.post("StandingBoardEntry", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const StandingBoardEntryDataInsert = (formData) =>
  api
    .post(
      `StandingBoardEntry`,
      {
        ...body,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
        action: "StandingBoardEntryDataInsert",
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const StandingBoardEntryDataUpdate = ({ id, ...formData }) =>
  api
    .put(
      `StandingBoardEntry`,
      {
        ...body,
        action: "StandingBoardEntryDataUpdate",
        menukey: "standing-board-entry",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getStandingBoardEntryListSingle = (id) =>
  api.post(
    `StandingBoardEntry`,
    {
      ...body,
      action: "getStandingBoardEntryListSingle",
      menukey: "standing-board-entry",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const StandingBoardEntryDataDalete = (id) =>
  api
    .post(
      `StandingBoardEntry`,
      {
        ...body,
        action: "StandingBoardEntryDataDalete",
        menukey: "standing-board-entry",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getStandingBoardEntryItemsList = (params) =>
  api.post("standingBoardItems", { params }, options).then((res) => {
    return res.data.productlist;
  });

//End of Standing Board

//slow moving report

export const getslowmovingitemlist = () =>
  api
    .post(
      "Slow_Moving_Item_Report_list",
      {
        ...body,
        action: "getslowmoningList",
        menukey: "roleaccess",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getslowmoningDetils = (params) =>
  api.post("Slow_Moving_Item_Report_list", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getPickIndentList = (params) =>
  api
    .post(
      "issueinvoiceindent",
      { params, FacilityId: FacilityId, UseFor: UseFor },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const insertUpdateIssueIndentPick = (formData) =>
  api
    .post(
      `issueinvoiceindent`,
      {
        ...body,
        action: "insertUpdateIssueIndentPick",
        menukey: "issuevoucherindent",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => res.data);

//Stock summary api
export const StockSummary = (params) =>
  api.post("StockSummary", { params }, options).then((res) => {
    return res.data.datalist;
  });
//Stock summary WCMC api
export const StockSummaryWCMC = (params) =>
  api.post("StockSummary", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const StockItemName = (zoneData) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...zoneData }, options).then((res) => {
    return res;
  });
};

//Receive from warehouse Pick issue invoice insert
export const insertUpdateIssueInvoicePick = (formData) =>
  api
    .post(
      `receiveInvoiceInsertFromPicklist`,
      {
        ...body,
        action: "insertUpdateReceiveInvoicetPick",
        menukey: "receive",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => res.data);

//Stock summary Lot api
export const StockSummaryBylot = (params) =>
  api.post("StockSummarybylot", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Issue Details by Facility Report Api
export const IssueDetailsByFacility = (params) =>
  api.post("IssueDetailsByFacility", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Issue Details by Facility Report Api
export const gatFacilityeLMISActivityStatusReport = (params) =>
  api.post("FacilityeLMISActivityStatusReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Stock summary Lot api
export const StockSummaryBylot11 = (params) =>
  api.post("StockSummarybylot", { params }, options).then((res) => {
    return res.data.datalist;
  });
  export const WCMCStockSummaryBylot = (params) =>
  api.post("StockSummarybylot", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const StockItemName11 = (zoneData) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...zoneData }, options).then((res) => {
    return res;
  });
};

//Issue Details by Item Report Api
export const IssueDetailsByItem = (params) =>
  api.post("IssueDetailsByItem", { params }, options).then((res) => {
    return res.data.datalist;
  });
  //Dispense Details by Item Report Api
export const DispenseDetailsByItem = (params) =>
api.post("DispenseDetailsbyItem", { params }, options).then((res) => {
  return res.data.datalist;
});
  // MCWC Dispense Details by Item Report Api
  export const MCWCDispenseDetailsByItem = (params) =>
  api.post("DispenseDetailsbyItem", { params }, options).then((res) => {
    return res.data.datalist;
  });
  //Dispense Summary Report Api
  export const DispenseSummary = (params) =>
  api.post("DispenseDetailsbyItem", { params }, options).then((res) => {
    return res.data.datalist;
  });
    // mcwc Dispense Summary Report Api
    export const MCWCDispenseSummary = (params) =>
    api.post("DispenseDetailsbyItem", { params }, options).then((res) => {
      return res.data.datalist;
    });
  
//Receive Report Report Api
export const ReceiveReport = (params) =>
  api.post("ReceiveReport", { params }, options).then((res) => {
    return res.data.datalist;
  });
//Receive Report Report Api
export const ReceiveReportMCWC = (params) =>
  api.post("ReceiveReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Adjustments Report Api
export const AdjustmentsReport = (params) =>
  api.post("AdjustmentsReport", { params }, options).then((res) => {
    return res.data.datalist;
  });
//Adjustments Report Api
export const AdjustmentsReportMCWC = (params) =>
  api.post("AdjustmentsReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Digital ICR Report Api
export const DigitalICR = (params) =>
  api.post("DigitalICR", { params }, options).then((res) => {
    return res.data.datalist;
  });
  //Digital ICR Report Api
export const MCWCDigitalICR = (params) =>
api.post("DigitalICR", { params }, options).then((res) => {
  return res.data.datalist;
});

//Expiry summary By Lot api
export const StockExpiryByLotAPI = (params) =>
  api.post("StockExpiryByLot", { params }, options).then((res) => {
    return res.data.datalist;
  });
//Expiry MCWX By Lot api
export const MCWCStockExpiryByLot = (params) =>
  api.post("StockExpiryByLot", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Stock Transactions by Lot Report Api
export const StockTransactionsByLot = (params) =>
  api.post("StockTransactionsByLot", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Start Return to the Supplier from Provisional Receive API

export const getReturnTotheSupplierFromProvisionalReceiveList = (params) =>
  api
    .post("ReturnTotheSupplierFromProvisionalReceive", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });

export const ReturnTotheSupplierFromProvisionalReceiveDataInsert = (formData) =>
  api
    .post(
      `ReturnTotheSupplierFromProvisionalReceive`,
      {
        ...body,
        action: "ReturnTotheSupplierFromProvisionalReceiveDataInsert",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const ReturnTotheSupplierFromProvisionalReceiveDataUpdate = ({
  id,
  ...formData
}) =>
  api
    .put(
      `ReturnTotheSupplierFromProvisionalReceive`,
      {
        ...body,
        action: "ReturnTotheSupplierFromProvisionalReceiveDataUpdate",
        UserName: UserName,
        lan: lan,
        menukey: "return-to-the-supplier-from-provisional-receive",
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getReturnTotheSupplierFromProvisionalReceiveListSingle = (id) =>
  api.post(
    `ReturnTotheSupplierFromProvisionalReceive`,
    {
      ...body,
      action: "getReturnTotheSupplierFromProvisionalReceiveListSingle",
      menukey: "return-to-the-supplier-from-provisional-receive",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const ReturnTotheSupplierFromProvisionalReceiveDataDalete = (id) =>
  api
    .post(
      `ReturnTotheSupplierFromProvisionalReceive`,
      {
        ...body,
        action: "ReturnTotheSupplierFromProvisionalReceiveDataDalete",
        UserName: UserName,
        lan: lan,
        menukey: "return-to-the-supplier-from-provisional-receive",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getReturnProvisionalReceiveItemsList = (params) =>
  api.post("returnProvisionalReceiveItems", { params }, options).then((res) => {
    return res.data.productlist;
  });

//End of Supplier from Provisional Receive API

//Provisional Receiving Register Report Api
export const ProvisionalReceivingRegister = (params) =>
  api.post("ProvisionalReceivingRegister", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Provisional Summary Report Api
export const ProvisionalSummaryReport = (params) =>
  api.post("ProvisionalSummaryReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

//physicalInventory
export const physicalInventoryReport = (params) =>
  api.post("PhysicalInventory", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Entry & Edit of Package from
export const getEntryandEditofPackageList = () =>
  api
    .post(
      "entryAndEditofPackages",
      {
        ...body,
        action: "getEntryandEditofPackageLists",
        menukey: "entry-and-edit-of-package",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const getEntryandEditofPackage = (id) =>
  api.post(
    `entryAndEditofPackages`,
    {
      ...body,
      action: "geEntryandEditofPackageList",
      menukey: "entry-and-edit-of-package",
      id: id,
    },
    options
  );
export const saveEntryandEditofPackageList = (getEntryandEditofPackageList1) =>
  api
    .post(
      `entryAndEditofPackages`,
      {
        ...body,
        action: "dataInsert",
        menukey: "entry-and-edit-of-package",
        FacilityId: FacilityId,
        ...getEntryandEditofPackageList1,
      },
      options
    )
    .then((res) => res.data);

export const deleteEntryandEditofPackageList = (id) =>
  api.post(`entryAndEditofPackages`, id, options);

export const updateEntryandEditofPackageList = ({
  id,
  ...updateEntryandEditofPackageLists
}) =>
  api
    .put(
      `entryAndEditofPackages`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "entry-and-edit-of-package",
        FacilityId: FacilityId,
        id: id,
        ...updateEntryandEditofPackageLists,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getContractListData = (params) =>
  api.post("entryAndEditofPackages", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveEntryandEditofContactList = (getEntryandEditofContactList1) =>
  api
    .post(
      `entryAndEditofPackages`,
      {
        ...body,
        action: "dataInsertContract",
        menukey: "entry-and-edit-of-package",
        FacilityId: FacilityId,
        ...getEntryandEditofContactList1,
      },
      options
    )
    .then((res) => res.data);

export const updateEntryandEditofContractList = ({
  id,
  ...updateEntryandEditofContractLists
}) =>
  api
    .put(
      `entryAndEditofPackages`,
      {
        ...body,
        action: "dataUpdateContract",
        menukey: "entry-and-edit-of-package",
        FacilityId: FacilityId,
        id: id,
        ...updateEntryandEditofContractLists,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getEntryandEditofContract = (id) =>
  api.post(
    `entryAndEditofPackages`,
    {
      ...body,
      action: "geEntryandEditofContractList",
      menukey: "entry-and-edit-of-package",
      id: id,
    },
    options
  );

//End Entry & Edit of Package from

//Get edit Item Contract

export const getYearId = () =>
  api
    .post(
      "ContrackItemEntry",
      {
        ...body,
        action: "YearSelect",
        menukey: "entry-edit-of-contract-items",
      },
      options
    )
    .then((res) => {
      return res.data;
    });

//Item Contract Edit
export const getContrackItemEntryList = (params) =>
  api.post("ContrackItemEntry", { params }, options).then((res) => {
    return res.data.datalist;
  });
export const getContrackItem = (id) =>
  api.post(
    `ContrackItemEntry`,
    {
      ...body,
      action: "getContrackItemEdit",
      menukey: "entry-edit-of-contract-items",
      ContractItemId: id,
    },
    options
  );

export const saveContractItem = (getContractItem1) =>
  api
    .post(
      `ContrackItemEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "entry-edit-of-contract-items",
        ...getContractItem1,
      },
      options
    )
    .then((res) => res.data);

export const UpdateontrackItem = ({ id, ...updateabcsd }) =>
  api
    .put(
      `ContrackItemEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "ContrackItemEntry",
        ContractItemId: id,
        ...updateabcsd,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Product Item List

export const getEntryofproductList = (params) =>
  api.post("entryofproduct", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getItemListCode = (params) =>
  api.post("entryofproduct", { ...body, params }, options).then((res) => {
    return res.data.datalist;
  });

//save query
export const saveEntryofProdcutItem = (getproductlissss) =>
  api
    .post(
      `entryofproduct`,
      {
        ...body,
        action: "dataInsert",
        menukey: "entry-edit-of-products",
        ...getproductlissss,
      },
      options
    )
    .then((res) => res.data);

export const getEnteryofProducts = (id) =>
  api.post(
    `entryofproduct`,
    {
      ...body,
      action: "geEntryofproduct",
      menukey: "entry-edit-of-products",
      mItemID: id,
    },
    options
  );
export const updateItemProduct = ({ id, ...updateItemProducts }) =>
  api
    .put(
      `entryofproduct`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "entry-edit-of-products",
        mItemID: id,
        ...updateItemProducts,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Union Entery page
export const getUnionList = (params) =>
  api.post("unionserverentry", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveUnionList = (getnionList) =>
  api
    .post(
      `unionserverentry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "union-entry-form",
        ...getnionList,
      },
      options
    )
    .then((res) => res.data);

export const getUnion = (id) =>
  api.post(
    `unionserverentry`,
    { ...body, action: "getunion", menukey: "union-entry-forms", id: id },
    options
  );

export const UpdaeUnionData = ({ id, ...UpdateUnionData }) =>
  api
    .put(
      `unionserverentry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "union-entry-forms",
        UnionCode: id,
        ...UpdateUnionData,
      },
      options
    )
    .then((res) => {
      return res;
    });
//Country Entry List
export const getCountryEntryLists = () =>
  api
    .post(
      "CountryEntry",
      { ...body, action: "getCountryEntryList", menukey: "country-entry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
    export const geCountryEntry = (id) =>
    api.post(
      `CountryEntry`,
      {
        ...body,
        action: "geCountryEntry",
        menukey: "country-entry",
        CountryId: id,
      },
      options
    );
    export const saveCountryEntry = (getCountryEntry1) =>
    api
      .post(
        `CountryEntry`,
        {
          ...body,
          action: "dataInsert",
          menukey: "country-entry",
          ...getCountryEntry1,
        },
        options
      )
      .then((res) => res.data);
      
    export const updateCountryEntry = ({ id, ...updateCountryEntryd }) =>
    api
      .put(
        `CountryEntry`,
        {
          ...body,
          action: "dataUpdate",
          menukey: "country-entry",
          CountryId: id,
          ...updateCountryEntryd,
        },
        options
      )
      .then((res) => {
        return res;
      });
  export const deleteCountryEntry = (id) => api.post(`CountryEntry`, id, options);
  // DDSkit Item Serial List
  export const getDDSkitItemSerialLists = () =>
  api
    .post(
      "DDSKitItemList",
      { ...body, action: "getDDSkitItemSerialList", menukey: "dds-kit-item-serial" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
// Asset Unit List
export const getAssetUnitLists = () =>
  api
    .post(
      "AssetUnit",
      { ...body, action: "getAssetUnitList", menukey: "asset-unit-entry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

    export const geAssetUnit = (id) =>
    api.post(
      `AssetUnit`,
      {
        ...body,
        action: "geAssetUnit",
        menukey: "asset-unit-entry",
        SectionId: id,
      },
      options
    );
    export const saveAssetUnit = (getAssetUnit1) =>
    api
      .post(
        `AssetUnit`,
        {
          ...body,
          action: "dataInsert",
          menukey: "asset-unit-entry",
          ...getAssetUnit1,
        },
        options
      )
      .then((res) => res.data);
    export const updateAssetUnit  = ({ id, ...updateAssetUnitd }) =>
    api
      .put(
        `AssetUnit`,
        {
          ...body,
          action: "dataUpdate",
          menukey: "asset-unit-entry",
          SectionId: id,
          ...updateAssetUnitd,
        },
        options
      )
      .then((res) => {
        return res;
      });
  export const deleteAssetUnit = (id) => api.post(`AssetUnit`, id, options);
  
// Asset Maintenance Schedule List
export const getAssetMaintenanceScheduleLists = () =>
  api
    .post(
      "AssetMaintenanceScheduleEntry",
      { ...body, action: "getAssetMaintenanceScheduleList", menukey: "asset-maintenance-schedule" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

    export const geAssetMaintenanceSchedule = (id) =>
    api.post(
      `AssetMaintenanceScheduleEntry`,
      {
        ...body,
        action: "geAssetMaintenanceSchedule",
        menukey: "asset-maintenance-schedule",
        MScheduleId: id,
      },
      options
    );
    export const saveAssetMaintenanceSchedule = (geAssetMaintenanceSchedule1) =>
    api
      .post(
        `AssetMaintenanceScheduleEntry`,
        {
          ...body,
          action: "dataInsert",
          menukey: "asset-maintenance-schedule",
          ...geAssetMaintenanceSchedule1,
        },
        options
      )
      .then((res) => res.data);
      export const updateAssetMaintenanceSchedule= ({ id, ...updateAssetMaintenanceScheduleid }) =>
      api
        .put(
          `AssetMaintenanceScheduleEntry`,
          {
            ...body,
            action: "dataUpdate",
            menukey: "asset-maintenance-schedule",
            MScheduleId: id,
            ...updateAssetMaintenanceScheduleid,
          },
          options
        )
        .then((res) => {
          return res;
        });
    export const deleteAssetMaintenanceSchedule = (id) => api.post(`AssetMaintenanceScheduleEntry`, id, options);
    
// Department List
export const getDepartmentLists = () =>
  api
    .post(
      "DepartmentEntry",
      { ...body, action: "getDepartmentList", menukey: "department-entry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

  export const geDepartment = (id) =>
  api.post(
    `DepartmentEntry`,
    {
      ...body,
      action: "geDepartment",
      menukey: "department-entry",
      DeptId: id,
    },
    options
  );
  export const saveDepartment = (getDepartment1) =>
  api
    .post(
      `DepartmentEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "department-entry",
        ...getDepartment1,
      },
      options
    )
    .then((res) => res.data);
  export const updateDepartment = ({ id, ...updateDepartmentd }) =>
  api
    .put(
      `DepartmentEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "department-entry",
        DeptId: id,
        ...updateDepartmentd,
      },
      options
    )
    .then((res) => {
      return res;
    });
export const deleteDepartment  = (id) => api.post(`DepartmentEntry`, id, options);

//StoreType List
export const getStoreTypeLists = () =>
  api
    .post(
      "StoreTypeEntry",
      { ...body, action: "getStoreTypeList", menukey: "store-type-entry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const geStoreType = (id) =>
  api.post(
    `StoreTypeEntry`,
    {
      ...body,
      action: "geStoreType",
      menukey: "store-type-entry",
      StoreTypeId: id,
    },
    options
  );
export const saveStoreType = (getStoreType1) =>
  api
    .post(
      `StoreTypeEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "store-type-entry",
        ...getStoreType1,
      },
      options
    )
    .then((res) => res.data);

export const deleteStoreType = (id) => api.post(`StoreTypeEntry`, id, options);

export const updateStoreType = ({ id, ...updateStoreTypesd }) =>
  api
    .put(
      `StoreTypeEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "store-type-entry",
        StoreTypeId: id,
        ...updateStoreTypesd,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Entry supplier

export const getEntrySupplierUims = () =>
  api
    .post(
      "EntryOfSupplier",
      {
        ...body,
        action: "getEntryOfSupplierList",
        menukey: "entry-edit-of-supplier",
        FacilityId: FacilityId,
        UseFor: UseFor,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getSupplier = (id) =>
  api.post(
    `EntryOfSupplier`,
    {
      ...body,
      action: "getEntryOfSupplier",
      menukey: "entry-edit-of-supplier",
      SupCode: id,
      FacilityId: FacilityId,
      UseFor: UseFor,
    },
    options
  );

export const saveSupplier = (getSupplier) =>
  api
    .post(
      `EntryOfSupplier`,
      {
        ...body,
        action: "dataInsert",
        menukey: "entry-edit-of-supplier",
        FacilityId: FacilityId,
        UseFor: UseFor,
        ...getSupplier,
      },
      options
    )
    .then((res) => res.data);

export const updateSupplier = ({ id, ...updateSupplier }) =>
  api
    .put(
      `EntryOfSupplier`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "entry-edit-of-supplier",
        SupCode: id,
        FacilityId: FacilityId,
        UseFor: UseFor,
        ...updateSupplier,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Health Facility Entry form
export const getHealthFacilityList = (params) =>
  api.post("HealthFacilityEntry", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getHealthFacilityListSingle = (id) =>
  api.post(
    `HealthFacilityEntry`,
    {
      ...body,
      action: "getHealthFacilityListSingle",
      menukey: "health-facility-entry",
      id: id,
    },
    options
  );
export const saveHealthFacilityList = (getHealthFacilityLists) =>
  api
    .post(
      `HealthFacilityEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "health-facility-entry",
        FacilityId: FacilityId,
        ...getHealthFacilityLists,
      },
      options
    )
    .then((res) => res.data);

export const deleteHealthFacilityList = (id) =>
  api.post(`HealthFacilityEntry`, id, options);

export const updateHealthFacilityList = ({ id, ...updateHealthFacilityList }) =>
  api
    .put(
      `HealthFacilityEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "health-facility-entry",
        FacilityId: FacilityId,
        id: id,
        ...updateHealthFacilityList,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Unit Entry From

export const getUnitEntry = (params) =>
  api.post("UnitEntry", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveUnitEntry = (getUnitEntry) =>
  api
    .post(
      `UnitEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "unit-entry",
        ...getUnitEntry,
      },
      options
    )
    .then((res) => res.data);

export const getUnitEntrys = (id) =>
  api.post(
    `UnitEntry`,
    { ...body, action: "getUnitEn", menukey: "unit-entry", id: id },
    options
  );

export const UpdateUnitEn = ({ id, ...UpdateUnitE }) =>
  api
    .put(
      `UnitEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "unit-entry",
        sunitid: id,
        ...UpdateUnitE,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const deleteUnit = (id) => api.post(`UnitEntry`, id, options);

// Upzila Enery Edit page
export const getEntryofUpazilaLists = () =>
  api
    .post(
      "EntryofUpazila",
      {
        ...body,
        action: "getEntryofUpazilaList",
        menukey: "entry-edit-of-upazila",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const saveUpazilaEntry = (getUpazila) =>
  api.post(
    `EntryofUpazila`,
    {
      ...body,
      action: "dataInsert",
      menukey: "entry-edit-of-upazila",
      FacilityId: FacilityId,
      UseFor: UseFor,
      ...getUpazila,
    },
    options
  );
export const getEntryAndEidtUpazila = (id) =>
  api.post(
    `EntryofUpazila`,
    {
      ...body,
      action: "getEntryEditUpazila",
      menukey: "entry-edit-of-upazila",
      uprec_id: id,
      FacilityId: FacilityId,
      UseFor: UseFor,
    },
    options
  );

export const updateUpazila = ({ upazila_id, id, ...updateUpazila }) =>
  api
    .put(
      `EntryofUpazila`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "entry-edit-of-upazila",
        uprec_id: id,
        upazila_id: upazila_id,
        FacilityId: FacilityId,
        UseFor: UseFor,
        ...updateUpazila,
      },
      options
    )
    .then((res) => {
      return res;
    });

    // MCWC Enery Edit page
    export const getEntryofMCWCLists = (params) =>
  api.post("EntryofMCWC", { params }, options).then((res) => {
    return res.data.datalist;
  });


export const saveMCWCEntry = (getUpazila) =>
api.post(
  `EntryofMCWC`,
  {
    ...body,
    action: "dataInsert",
    menukey: "entry-edit-of-mcwc",
    FacilityId: FacilityId,
    UseFor: UseFor,
    ...getUpazila,
  },
  options
);
export const getEntryAndEidtMCWC = (id) =>
api.post(
  `EntryofMCWC`,
  {
    ...body,
    action: "getEntryEdit",
    menukey: "entry-edit-of-mcwc",
    uprec_id: id,
    FacilityId: FacilityId,
    UseFor: UseFor,
  },
  options
);

export const updateMCWC = ({ id, ...updateUpazila }) =>
api
  .put(
    `EntryofMCWC`,
    {
      ...body,
      action: "dataUpdate",
      menukey: "entry-edit-of-mcwc",
      uprec_id: id,
      FacilityId: FacilityId,
      UseFor: UseFor,
      ...updateUpazila,
    },
    options
  )
  .then((res) => {
    return res;
  });
//end mcwc entry

//Empleyee

export const getEmployeeList = () =>
  api
    .post(
      "Employee",
      {
        ...body,
        action: "getEmployeeList",
        menukey: "employee-entry",
        FacilityId: FacilityId,
        UseFor: UseFor,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const saveEmployee = (getEmployee) =>
  api
    .post(
      `Employee`,
      {
        ...body,
        action: "dataInsert",
        menukey: "employee-entry",
        FacilityId: FacilityId,
        ...getEmployee,
      },
      options
    )
    .then((res) => res.data);

export const getEmployeess = (id) =>
  api.post(
    `Employee`,
    {
      ...body,
      action: "getEmployee",
      menukey: "employee-entry",
      EmpCode: id,
      FacilityId: FacilityId,
    },
    options
  );

export const updateEmployee = ({ id, ...updateEmployees }) =>
  api
    .put(
      `Employee`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "employee-entry",
        EmpCode: id,
        FacilityId: FacilityId,
        ...updateEmployees,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const EmployeeDelete = (id) => api.post(`Employee`, id, options);

//SDP List

export const getSDPList = (params) =>
  api.post("SdpList", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveSDPList = (getSDP) =>
  api
    .post(
      `SdpList`,
      {
        ...body,
        action: "dataInsert",
        menukey: "sdplist",
        FacilityId: FacilityId,
        ...getSDP,
      },
      options
    )
    .then((res) => res.data);
export const getSDPListss = (id) =>
  api.post(
    `SdpList`,
    { ...body, action: "getSDP", menukey: "sdplist", id: id },
    options
  );
export const UpdaeSDPData = ({ id, ...UpdateSDPData }) =>
  api
    .put(
      `SdpList`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "sdplist",
        FacilityCode: id,
        ...UpdateSDPData,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const deleteSDPList = (id) => api.post(`SdpList`, id, options);

//Start Local Purchase

export const getLocalPurchaseList = (params) =>
  api.post("LocalPurchase", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const LocalPurchaseDataInsert = (formData) =>
  api
    .post(
      `LocalPurchase`,
      {
        ...body,
        action: "LocalPurchaseDataInsert",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const LocalPurchaseDataUpdate = ({ id, ...formData }) =>
  api
    .put(
      `LocalPurchase`,
      {
        ...body,
        action: "LocalPurchaseDataUpdate",
        menukey: "local-purchase",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const LocalPurchaseUnPost = ({ id, ...formData }) =>
  api
    .put(
      `LocalPurchase`,
      {
        ...body,
        action: "LocalPurchaseUnPost",
        menukey: "local-purchase",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getLocalPurchaseSupplierInv = (id) =>
  api.post(
    `LocalPurchase`,
    {
      ...body,
      action: "getLocalPurchaseListSingle",
      menukey: "local-purchase",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const LocalPurchaseDataDalete = (id) =>
  api
    .post(
      `LocalPurchase`,
      {
        ...body,
        action: "LocalPurchaseDataDalete",
        menukey: "local-purchase",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getLocalPurchaseContractList = () =>
  api
    .post(
      "LocalPurchase",
      {
        ...body,
        action: "getLocalPurchaseContractList",
        menukey: "local-purchase",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const postLocalPurchase = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "local-purchase",
        FacilityId: FacilityId,
        TableName: "recinv",
        TransactionId: id,
        TransactionTypeId: 1,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });

//End of Local Purchase

//Start Local Purchase Facility

export const getLocalPurchaseFacilityList = (params) =>
  api.post("LocalPurchaseFacility", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const LocalPurchaseFacilityDataInsert = (formData) =>
  api
    .post(
      `LocalPurchaseFacility`,
      {
        ...body,
        action: "LocalPurchaseDataInsert",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const LocalPurchaseFacilityDataUpdate = ({ id, ...formData }) =>
  api
    .put(
      `LocalPurchaseFacility`,
      {
        ...body,
        action: "LocalPurchaseDataUpdate",
        menukey: "local-purchase-facility",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });



export const getLocalPurchaseFacilitySupplierInv = (id) =>
  api.post(
    `LocalPurchaseFacility`,
    {
      ...body,
      action: "getLocalPurchaseListSingle",
      menukey: "local-purchase-facility",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const LocalPurchaseFacilityDataDalete = (id) =>
  api
    .post(
      `LocalPurchaseFacility`,
      {
        ...body,
        action: "LocalPurchaseDataDalete",
        menukey: "local-purchase",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });


//End of Local Purchase

//Upazila List Report Api
export const UpazilaList = (params) =>
  api.post("upazila_list", { params }, options).then((res) => {
    return res.data.datalist;
  });

//SDP Stock Out Report Api
export const SDPStockOutReportList = (params) =>
  api.post("sdpstockoutreport", { params }, options).then((res) => {
    return res.data.datalist;
  });

//From 2/3 Submission Report Api
export const From23SubmissionReportList = (params) =>
  api.post("sdpstockoutreport", { params }, options).then((res) => {
    return res.data.datalist;
  });

//SDP Distribution Report Api
export const SDPDistributionReporttList = (params) =>
  api.post("sdpstockoutreport", { params }, options).then((res) => {
    return res.data.datalist;
  });
//SDP Distribution Summary Api
export const SDPDistributionSummaryList = (params) =>
  api.post("sdpstockoutreport", { params }, options).then((res) => {
    return res.data.datalist;
  });

//SDP Ledger Report Api
export const SDPLedgerReportList = (params) =>
  api.post("sdpstockoutreport", { params }, options).then((res) => {
    return res.data.datalist;
  });
//SDP Closing Summary Api
export const SDPClosingSummaryList = (params) =>
  api.post("sdpstockoutreport", { params }, options).then((res) => {
    return res.data.datalist;
  });
//SDP Active/inactive Summary Api
export const SDPActiveInactiveReportList = (params) =>
  api.post("sdpstockoutreport", { params }, options).then((res) => {
    return res.data.datalist;
  });

///Issue Voucher for UIMS
export const getIssueVoucher = (params) =>
  api.post("IssueVoucher", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveIssueVoucherInv = (dispenserData) =>
  api
    .post(
      `IssueVoucher`,
      {
        ...body,
        action: "IssueVoucherSave",
        menukey: "issue-voucher",
        UserName: UserName,
        lan: lan,
        ...dispenserData,
      },
      options
    )
    .then((res) => res.data);

export const issueVoucherUpdateUIMS = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `IssueVoucher`,
      {
        ...body,
        action: "IssueVoucherUpdate",
        menukey: "issue-voucher",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getIssueVoucherSingleUIMS = (id) =>
  api.post(
    `IssueVoucher`,
    {
      ...body,
      action: "getIssueVoucherSingle",
      menukey: "issue-voucher",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const deleteIssueVoucherInv = (id) =>
  api
    .post(
      `IssueVoucher`,
      {
        ...body,
        menukey: "issue-voucher",
        action: "deleteIssueVoucherInv",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const postIssueVoucher = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "issue-voucher",
        FacilityId: FacilityId,
        TableName: "indent",
        TransactionId: id,
        TransactionTypeId: 1,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });

/*Start of Dispense Facility*/
export const getDispenseFacility = (params) =>
  api.post("DispenseFacility", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveDispenseFacilityInv = (dispenserData) =>
  api
    .post(
      `DispenseFacility`,
      {
        ...body,
        action: "DispenseFacilitySave",
        menukey: "dispense-facility",
        UserName: UserName,
        lan: lan,
        ...dispenserData,
      },
      options
    )
    .then((res) => res.data);

export const DispenseFacilityUpdateUIMS = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `DispenseFacility`,
      {
        ...body,
        action: "DispenseFacilityUpdate",
        menukey: "dispense-facility",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });
	
export const getDispenseFacilitySingleUIMS = ({ id, ...updatedDispenser }) =>
  api.post(
    `DispenseFacility`,
    {
      ...body,
      action: "getDispenseFacilitySingle",
      menukey: "dispense-facility",
      TransactionId: id,
      FacilityId: FacilityId,
	  ...updatedDispenser,
    },
    options
  ); 
  
/* export const getDispenseFacilitySingleUIMS = (id) =>
  api.post(
    `DispenseFacility`,
    {
      ...body,
      action: "getDispenseFacilitySingle",
      menukey: "dispense-facility",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  ); */


export const deleteDispenseFacilityInv = (id) =>
  api
    .post(
      `DispenseFacility`,
      {
        ...body,
        menukey: "dispense-facility",
        action: "deleteDispenseFacilityInv",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });
 
export const postDispenseFacility = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "dispense-facility",
        FacilityId: FacilityId,
        TableName: "indent",
        TransactionId: id,
        TransactionTypeId: 1,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });
	
/*End of Dispense Facility*/

///Star Form 7B UIMS
export const getForm7Buims = (params) =>
  api.post("form7buims", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getForm7BuimsSingle = (id) =>
  api.post(
    `form7buims`,
    {
      ...body,
      action: "getForm7BuimsSingle",
      menukey: "form-7b-uims",
      TransactionId: id,
      TransferFacilityCode: FacilityId,
      FacilityId: FacilityId,
      UseFor: UseFor,
    },
    options
  );

export const saveForm7BUIMS = (dispenserData) =>
  api
    .post(
      `form7buims`,
      {
        ...body,
        action: "Form7BSaveuims",
        menukey: "form-7b-uims",
        UserName: UserName,
        lan: lan,
        ...dispenserData,
      },
      options
    )
    .then((res) => res.data);

export const Form7BUpdateUIMS = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `form7buims`,
      {
        ...body,
        action: "Form7BUpdateUIMS",
        menukey: "form-7b-uims",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const deleteForm7buims = (id) =>
  api
    .post(
      `form7buims`,
      {
        ...body,
        menukey: "form-7b-uims",
        action: "deleteForm7BUims",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const deleteForm7BItemsUims = (id) =>
  api
    .post(
      `form7buims`,
      {
        ...body,
        menukey: "form-7b-uims",
        action: "deleteForm7BItemsUims",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const checkItemMistake = (params) =>
  api
    .post(
      "form7buims",
      {
        ...body,
        action: "checkItemMistake",
        menukey: "form-7b-uims",
        FacilityId: FacilityId,
        YearId: params.queryKey[1],
        MonthId: params.queryKey[2],
      },
      options
    )
    .then((res) => {
      return res.data.ismistakeinv;
    });

export const getItemMistakeStatus = (params) =>
  api
    .post(
      "form7buims",
      {
        ...body,
        action: "getItemMistakeStatus",
        menukey: "form-7b-uims",
        FacilityId: FacilityId,
        YearId: params.queryKey[1],
        MonthId: params.queryKey[2],
      },
      options
    )
    .then((res) => {
      return res.data.productlist;
    });

export const getHasUnPostInvoiceByMonthForm7BUims = (params) =>
  api
    .post(
      "form7buims",
      {
        ...body,
        action: "getHasUnPostInvoiceByMonthForm7BUims",
        menukey: "form-7b-uims",
        FacilityId: FacilityId,
        YearId: params.queryKey[1],
        MonthId: params.queryKey[2],
      },
      options
    )
    .then((res) => {
      return res.data.isunpostinv;
    });

export const getUnPostCheckByMonthForm7BUims = (params) =>
  api
    .post(
      "form7buims",
      {
        ...body,
        action: "getUnPostCheckByMonthForm7BUims",
        menukey: "form-7b-uims",
        FacilityId: FacilityId,
        YearId: params.queryKey[1],
        MonthId: params.queryKey[2],
      },
      options
    )
    .then((res) => {
      return res.data.isunpostinv;
    });

export const getInvoiceStatusByMonthUIMS = (params) =>
  api
    .post(
      "form7buims",
      {
        ...body,
        action: "getInvoiceStatus",
        menukey: "form-7b-uims",
        FacilityId: FacilityId,
        YearId: params.queryKey[1],
        MonthId: params.queryKey[2],
      },
      options
    )
    .then((res) => {
      return res.data.productlist;
    });

export const generateForm7BItemsUims = (id) =>
  api
    .post(
      `form7buims`,
      {
        ...body,
        menukey: "form-7b-uims",
        FacilityId: FacilityId,
        action: "generateForm7BItemsUIMS",
        TransactionId: id,
        TransactionTypeId: 0,
        UserName: UserName,
        lan: lan,
        UseFor: UseFor,
      },
      options
    )
    .then((res) => {
      return res;
    });

///End Form 7B UIMS

///////////////////////////////////////////

///Start LMIS Form 3
export const getLMISForm3List = (params) =>
  api.post("lmisform3", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getLMISForm3SettingItems = (id) =>
  api.post(
    `lmisform3`,
    {
      ...body,
      action: "getLMISForm3SettingItems",
      menukey: "lmisform3",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const saveLMISForm3 = (dispenserData) =>
  api
    .post(
      `lmisform3`,
      {
        ...body,
        action: "saveLMISForm3",
        menukey: "lmisform3",
        ...dispenserData,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => res.data);

export const LMISForm3Update = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `lmisform3`,
      {
        ...body,
        action: "LMISForm3Update",
        menukey: "lmisform3",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getLMISForm3Single = (id, currItemCode) =>
  api.post(
    `lmisform3`,
    {
      ...body,
      action: "getLMISForm3Single",
      menukey: "lmisform3",
      TransactionId: id,
      FacilityId: FacilityId,
      ItemCode: currItemCode,
    },
    options
  );
export const deleteLMISForm3 = (id) =>
  api
    .post(
      `lmisform3`,
      {
        ...body,
        menukey: "lmisform3",
        action: "deleteLMISForm3",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const deleteLMISForm3Items = (id) =>
  api
    .post(
      `lmisform3`,
      {
        ...body,
        menukey: "lmisform3",
        action: "deleteLMISForm3Items",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const generateLMISForm3Items = (id) =>
  api
    .post(
      `lmisform3`,
      {
        ...body,
        menukey: "lmisform3",
        FacilityId: FacilityId,
        action: "generateLMISForm3Items",
        TransactionId: id,
        TransactionTypeId: 0,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getForm3SDPList = (amcData) => {
  return api.post("comboscript", { ...amcData }, options).then((res) => {
    return res;
  });
};

///End LMIS Form 3

//Start MCWC Monthly Form 3
export const getMCWCMonthlyForm3List = (params) =>
  api.post("mcwc_monthly_form3", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getMCWCMonthlyForm3SettingItems = (id) =>
  api.post(
    `mcwc_monthly_form3`,
    {
      ...body,
      action: "getMCWCMonthlyForm3SettingItems",
      menukey: "mcwc-monthly-form-3",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const saveMCWCMonthlyForm3 = (dispenserData) =>
  api
    .post(
      `mcwc_monthly_form3`,
      {
        ...body,
        action: "saveMCWCMonthlyForm3",
        menukey: "mcwc-monthly-form-3",
        ...dispenserData,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => res.data);
	
export const MCWCMonthlyForm3Update = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `mcwc_monthly_form3`,
      {
        ...body,
        action: "MCWCMonthlyForm3Update",
        menukey: "mcwc-monthly-form-3",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getMCWCMonthlyForm3Single = (id, currItemCode) =>
  api.post(
    `mcwc_monthly_form3`,
    {
      ...body,
      action: "getMCWCMonthlyForm3Single",
      menukey: "mcwc-monthly-form-3",
      TransactionId: id,
      FacilityId: FacilityId,
      ItemCode: currItemCode,
    },
    options
  );
  
export const deleteMCWCMonthlyForm3 = (id) =>
  api
    .post(
      `mcwc_monthly_form3`,
      {
        ...body,
        menukey: "mcwc-monthly-form-3",
        action: "deleteMCWCMonthlyForm3",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const deleteMCWCMonthlyForm3Items = (id) =>
  api
    .post(
      `mcwc_monthly_form3`,
      {
        ...body,
        menukey: "mcwc-monthly-form-3",
        action: "deleteMCWCMonthlyForm3Items",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const generateMCWCMonthlyForm3Items = (id) =>
  api
    .post(
      `mcwc_monthly_form3`,
      {
        ...body,
        menukey: "mcwc-monthly-form-3",
        FacilityId: FacilityId,
        action: "generateMCWCMonthlyForm3Items",
        TransactionId: id,
        TransactionTypeId: 0,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });

//End MCWC Monthly Form 3

//Start Upazila Supply Plan

export const getUpazilaSupplyPlanList = (params) =>
  api.post("upazilasupplyplan", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getUIMSSupplySettingItems = (id) =>
  api.post(
    `upazilasupplyplan`,
    {
      ...body,
      action: "getUIMSSupplySettingItems",
      menukey: "upazila-supply-plan",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const saveUpazilaSupplyPlan = (dispenserData) =>
  api
    .post(
      `upazilasupplyplan`,
      {
        ...body,
        action: "UpazilaSupplyPlanSave",
        menukey: "upazila-supply-plan",
        ...dispenserData,
        UserName: UserName,
        lan: lan,
        UseFor: UseFor,
      },
      options
    )
    .then((res) => res.data);

export const UpazilaSupplyPlanUpdate = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `upazilasupplyplan`,
      {
        ...body,
        action: "UpazilaSupplyPlanUpdate",
        menukey: "upazila-supply-plan",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getUpazilaSupplyPlanSingle = (id, currItemCode) =>
  api.post(
    `upazilasupplyplan`,
    {
      ...body,
      action: "getUpazilaSupplyPlanSingle",
      menukey: "upazila-supply-plan",
      TransactionId: id,
      FacilityId: FacilityId,
      ItemCode: currItemCode,
    },
    options
  );
export const deleteUpazilaSupplyPlan = (id) =>
  api
    .post(
      `upazilasupplyplan`,
      {
        ...body,
        menukey: "upazila-supply-plan",
        action: "deleteUpazilaSupplyPlanMasterItems",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const deleteUpazilaSupplyPlanItems = (id) =>
  api
    .post(
      `upazilasupplyplan`,
      {
        ...body,
        menukey: "upazila-supply-plan",
        action: "deleteUpazilaSupplyPlanItems",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const generateUpazilaSupplyPlanItems = (id) =>
  api
    .post(
      `upazilasupplyplan`,
      {
        ...body,
        menukey: "upazila-supply-plan",
        FacilityId: FacilityId,
        action: "generateUpazilaSupplyPlanItems",
        TransactionId: id,
        TransactionTypeId: 0,
        UserName: UserName,
        lan: lan,
        UseFor: UseFor,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const generateUpazilaIssueVoucher = (id) =>
  api
    .post(
      `upazilasupplyplan`,
      {
        ...body,
        menukey: "upazila-supply-plan",
        FacilityId: FacilityId,
        action: "generateUpazilaIssueVoucher",
        TransactionId: id,
        TransactionTypeId: 0,
        UserName: UserName,
        UseFor: UseFor,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });

//End Upazila Supply Plan

////////////Start Upazila custom supply plan///////////////

export const getUpazilaCustomSupplyPlanItems = () =>
  api
    .post(
      "upazilacustomsupplyplan",
      {
        ...body,
        action: "getUpazilaCustomSupplyPlanItems",
        UseFor: UseFor,
        FacilityId: FacilityId,
        menukey: "upazila-custom-supply-plan",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const getUpazilaProfileItems = (defaultProfId) =>
  api
    .post(
      "upazilacustomsupplyplan",
      {
        ...body,
        action: "getUpazilaProfileItems",
        FacilityId: FacilityId,
        menukey: "upazila-custom-supply-plan",
        Params: defaultProfId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getUpazilaCustomSupplyPlanFacility = () =>
  api
    .post(
      "upazilacustomsupplyplan",
      {
        ...body,
        action: "getUpazilaCustomSupplyPlanFacility",
        UseFor: UseFor,
        FacilityId: FacilityId,
        menukey: "upazila-custom-supply-plan",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getUpazilaCustomSupplyPlanFacilityAndProductFinal = () =>
  api
    .post(
      "upazilacustomsupplyplan",
      {
        ...body,
        action: "getUpazilaCustomSupplyPlanFacilityAndProductFinal",
        FacilityId: FacilityId,
        menukey: "upazila-custom-supply-plan",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const CustomUpazilaSupplyPlanFacilityProductInsUp = (formData) =>
  api
    .post(
      `upazilacustomsupplyplan`,
      {
        ...body,
        menukey: "upazila-custom-supply-plan",
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
        action: "CustomUpazilaSupplyPlanFacilityProductInsUp",
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const addUpazilaProfile = ({ id, ...paramsData }) =>
  api
    .put(
      `upazilacustomsupplyplan`,
      {
        ...body,
        action: "addUpazilaProfile",
        menukey: "upazila-custom-supply-plan",
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
        ...paramsData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const copyUpazilaProfile = ({ id, ...paramsData }) =>
  api
    .put(
      `upazilacustomsupplyplan`,
      {
        ...body,
        action: "copyUpazilaProfile",
        menukey: "upazila-custom-supply-plan",
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
        ...paramsData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });
export const addUpazilaProfileItemAndFacilityMap = ({ id, ...paramsData }) =>
  api
    .put(
      `upazilacustomsupplyplan`,
      {
        ...body,
        action: "addUpazilaProfileItemAndFacilityMap",
        menukey: "upazila-custom-supply-plan",
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
        ...paramsData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

////////////End Upazila custom supply plan///////////////

//Start SDP Serial Modal
/* export const getSDPSerialList = () =>
  api
    .post(
      "SdpList",
      { ...body, action: "getSDPSerialList", FacilityId:FacilityId, menukey: "sdplist" },
      options
    )
    .then((res) => {
      return res.data.productlist;
    }); */

export const SdpList = (params) =>
  api.post("SdpList", { params }, options).then((res) => {
    return res.data.productlist;
  });

/*     export const StockSummary = (params) =>
  api.post("StockSummary", { params }, options).then((res) => {
    return res.data.datalist;
  }); */

export const updateSDPShortOrder = (params) =>
  //console.log(params.id);
  api
    .put(
      `SdpList`,
      {
        ...body,
        action: "serialUpdate",
        UserName: UserName,
        lan: lan,
        menukey: "sdplist",
        FacilityId: FacilityId,
        SDPData: params.SDPData,
      },
      options
    )
    .then((res) => {
      return res;
    });

//End SDP Serial Modal

//Entery of Edit warehouse

export const getEntryofWarehouseLists = () =>
  api
    .post(
      "EntryofWarehouse",
      {
        ...body,
        action: "getEntryofWarehouseList",
        menukey: "entry-edit-of-warehouse",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const saveWareHouseEntry = (getWarehouse) =>
  api.post(
    `EntryofWarehouse`,
    {
      ...body,
      action: "dataInsert",
      menukey: "entry-edit-of-warehouse",
      FacilityId: FacilityId,
      UseFor: UseFor,
      ...getWarehouse,
    },
    options
  );

export const getEntryWareHouses = (id) =>
  api.post(
    `EntryofWarehouse`,
    {
      ...body,
      action: "getEntryEditWareHouse",
      menukey: "entry-edit-of-warehouse",
      whrec_id: id,
      FacilityId: FacilityId,
      UseFor: UseFor,
    },
    options
  );

export const updateWareHouse = ({ wh_id, id, ...updateWareHouse }) =>
  api
    .put(
      `EntryofWarehouse`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "entry-edit-of-warehouse",
        whrec_id: id,
        wh_id: wh_id,
        FacilityId: FacilityId,
        UseFor: UseFor,
        ...updateWareHouse,
      },
      options
    )
    .then((res) => {
      return res;
    });



/////product group admin form

export const getTotalProductgroup = (params) =>
  api.post("productgroup", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getProductGroupList = (params) =>
  api.post("productgroup", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveProductGroup = (getunitofmeasure1) =>
  api
    .post(
      `productgroup`,
      {
        ...body,
        action: "dataInsert",
        menukey: "product-group-entry",
        ...getunitofmeasure1,
      },
      options
    )
    .then((res) => res.data);
export const deleteProductGroup = (id) =>
  api.post(`productgroup`, id, options);
export const getProductGroup = (id) =>
  api.post(
    `productgroup`,
    {
      ...body,
      action: "getProductGroup",
      menukey: "product-group-entry",
      ItemGroupId: id,
    },
    options
  );
export const upProductGroup = ({ id, ...updatedunitof }) =>
  api
    .put(
      `productgroup`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "product-group-entry",
        ItemGroupId: id,
        ...updatedunitof,
      },
      options
    )
    .then((res) => {
      return res;
    });

/////End product group admin form    


//National Supplier
export const getYearList = () =>
  api
    .post(
      "Year",
      {
        ...body,
        action: "getYearList",
        menukey: "year"
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const getYear = (id) =>
  api.post(
    `Year`,
    {
      ...body,
      action: "getYear",
      menukey: "year",
      YearId: id
    
    },
    options
  );
export const saveYear = (getYear1) =>
  api
    .post(
      `Year`,
      {
        ...body,
        action: "dataInsert",
        menukey: "year",
        ...getYear1,
      },
      options
    )
    .then((res) => res.data);

export const deleteYear = (id) =>
  api.post(`Year`, id, options);

export const updateYear = ({ id, ...updateYeardd }) =>
  api
    .put(
      `Year`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "year",
        SupCode: id,
        ...updateYeardd,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Start Physical Inventory Entry

export const getPhysicalInventoryEntryList = (params) =>
  api.post("PhysicalInventoryEntry", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const PhysicalInventoryEntryDataInsert = (formData) =>
  api
    .post(
      `PhysicalInventoryEntry`,
      {
        ...body,
        action: "PhysicalInventoryEntryDataInsert",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => res.data);

export const PhysicalInventoryEntryDataUpdate = ({ id, ...formData }) =>
  api
    .put(
      `PhysicalInventoryEntry`,
      {
        ...body,
        action: "PhysicalInventoryEntryDataUpdate",
        menukey: "physical-inventory-entry",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });
export const PhysicalInventoryEntryGeneratedDataUpdate = ({
  id,
  ...formData
}) =>
  api
    .put(
      `PhysicalInventoryEntry`,
      {
        ...body,
        action: "PhysicalInventoryEntryGeneratedDataUpdate",
        menukey: "physical-inventory-entry",
        UserName: UserName,
        lan: lan,
        UseFor: UseFor,
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const PhysicalInventoryEntryUnPost = ({ id, ...formData }) =>
  api
    .put(
      `PhysicalInventoryEntry`,
      {
        ...body,
        action: "PhysicalInventoryEntryUnPost",
        menukey: "physical-inventory-entry",
        UserName: UserName,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getPhysicalInventoryEntryInv = (sParams) =>
  api.post(
    `PhysicalInventoryEntry`,
    {
      ...body,
      action: "getPhysicalInventoryEntryListSingle",
      menukey: "physical-inventory-entry",
      ...sParams,
      FacilityId: FacilityId,
    },
    options
  );

export const PhysicalInventoryEntryDataDalete = (id) =>
  api
    .post(
      `PhysicalInventoryEntry`,
      {
        ...body,
        action: "PhysicalInventoryEntryDataDalete",
        menukey: "physical-inventory-entry",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getPhysicalInventoryEntryContractList = () =>
  api
    .post(
      "PhysicalInventoryEntry",
      {
        ...body,
        action: "getPhysicalInventoryEntryContractList",
        menukey: "physical-inventory-entry",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const postPhysicalInventoryEntry = (id) =>
  api
    .post(
      `PhysicalInventoryEntry`,
      {
        ...body,
        action: "postPhysicalInventoryEntry",
        menukey: "physical-inventory-entry",
        FacilityId: FacilityId,
        //TableName: "recinv",
        TransactionId: id,
        TransactionTypeId: 1,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const deletePhysicalInventoryItems = (id) =>
  api
    .post(
      `PhysicalInventoryEntry`,
      {
        ...body,
        menukey: "physical-inventory-entry",
        action: "deletePhysicalInventoryItems",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

//End of Physical Inventory Entry


//Start Upazila Form Item Serial
export const getFormItemSerialList = () =>
  api
    .post(
      "Form_Item_Serial_List",
      {
        ...body,
        action: "getFormItemSerialList",
        menukey: "form-item-serial",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getFormItemSerialDetails = (params) =>
  api.post("Form_Item_Serial_List", { params }, options).then((res) => {
    return res.data.datalist;
  });
  
//End Upazila Form Item Serial

// Start Warehouse Form Item Serial
export const getWarehouseFormItemSerialList = () =>
  api
    .post(
      "Warehouse_Form_Item_Serial_List",
      {
        ...body,
        action: "getWarehouseFormItemSerialList",
        menukey: "warehouse-form-item-serial",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getWarehouseFormItemSerialDetails = (params) =>
  api.post("Warehouse_Form_Item_Serial_List", { params }, options).then((res) => {
    return res.data.datalist;
  });
  
//End Warehouse Form Item Serial


//Start Warehouse Supply Plan Items Serial
export const getWarehouseSupplyPlanItemsSerialList = () =>
  api
    .post(
      "Warehouse_Supply_Plan_Items_Serial_List",
      {
        ...body,
        action: "getWarehouseSupplyPlanItemsSerialList",
        menukey: "warehouse-supply-plan-items-serial",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });


//End Warehouse Supply Plan Items Serial



//Start Upazila Supply Plan Items Serial
export const getUpazilaSupplyPlanItemsSerialList = () =>
  api
    .post(
      "Upazila_Supply_Plan_Items_Serial_List",
      {
        ...body,
        action: "getUpazilaSupplyPlanItemsSerialList",
        menukey: "upazila-supply-plan-items-serial",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });


//End Upazila Supply Plan Items Serial


    export const dirtyModeEnable = () => { 
      const element = document.getElementById("master_dirty_message");
      if(element){
        document.getElementById("master_dirty_message").classList.remove("dnone");
      }
   
    }

    export const dirtyModeDisable = () => { 
      const element = document.getElementById("master_dirty_message");
      if(element){
        document.getElementById("master_dirty_message").classList.add("dnone");
      }
    }


//DDFP Information Entry
export const getDDFPInformationList = (params) =>
  api.post("ddfpinformationentry", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getDDFPInformation = (id) =>
  api.post(
    `ddfpinformationentry`,
    { ...body, action: "getDDFPInformation", menukey: "ddfp-information-entry", id: id },
    options
  );

export const UpdaeDDFPInformationData = ({ id, ...UpdaeDDFPInformationsData }) =>
  api
    .put(
      `ddfpinformationentry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "ddfp-information-entry",
        DistrictId: id,
        ...UpdaeDDFPInformationsData,
      },
      options
    )
    .then((res) => {
      return res;
    });


    /* Start Catalog */




    export const saveNationalSupplierForCatalog = (getNationalSupplier1) =>
    api
      .post(
        `NationalSupplier`,
        {
          ...body,
          action: "dataInsertFromCatalog",
          menukey: "abc",
          UseFor: UseFor,
          ...getNationalSupplier1,
        },
        options
      )
      .then((res) => res.data);  

    /*
    export const getProductsListModalCatalog = () =>
    api
      .post(
        "products",
        { ...body, action: "getProducts", menukey: "products" },
        options
      )
      .then((res) => {
        return res.data.productlist;
      });  
      */
      
   export const getProductsListModalCatalog = (params) =>
  api
    .post("products", { params }, options)
    .then((res) => {
      return res.data.productlist;
    });


export const getCatalogSpecificationList = (params) =>
  api.post("CatalogSpecification", { params }, options).then((res) => {
    return res.data;
  });

export const getCatalogSpecificationListRpt = (params) =>
  api.post("CatalogSpecification", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getProductSpecification = (id) =>
  api.post(
    `CatalogSpecification`,
    {
      ...body,
      action: "getProductSpecification",
      menukey: "product-specification",
      SpecificationId: id.id,
      userId: id.UserName
    },
    options
  );


  export const getItemName = (params) =>
  api.post("CatalogSpecification", { ...body, params }, options).then((res) => {
    return res.data.datalist;
  });

  export const saveProductSpecification = (saveProSpecification) =>
  api
    .post(
      `CatalogSpecification`,
      {
        ...body,
        action: "dataInsert",
        menukey: "product-specification",
        ...saveProSpecification,
      },
      options
    )
    .then((res) => res.data);

    export const updateProductSpecification = ({ id, ...updatedspecification }) =>
    api
      .put(
        `CatalogSpecification`,
        {
          ...body,
          action: "dataUpdate",
          menukey: "product-specification",
          SpecificationId: id,
          ...updatedspecification,
        },
        options
      )
      .then((res) => {
        return res;
      });  



      export const getCatalogUserList = () =>
      api
        .post(
          "catalogusermap",
          { ...body, action: "getUserList", menukey: "catalog-user-map" },
          options
        )
        .then((res) => {
          return res.data.datalist;
        });
    
    export const getPackagePerEntry = (params) =>
      api.post("catalogusermap", { params }, options).then((res) => {
        return res.data.datalist;
      });  
      
      
  export const userToPackageInsertUpdateDelete = (params,pProps) =>
  //console.log(params.id);
  api
    .post(
      `catalogusermap`,
      {
        ...body,
        action: "dataInsertUpdateDelete",
        UserName: UserName,
        lan: lan,
        menukey: "catalog-user-map",
        user_id: params.selectedRoleData,
        PackageNo: params.menu_id,
        seteditpermission: params.seteditpermission,
      },
      options
    )
    .then((res) => {
      // console.log('res: ', res.data);
      // console.log(params);
      // console.log("pProps: ",pProps);

      // if (res.data.status == 200) {

      //   pProps.openNoticeModal({
      //     isOpen: true,
      //     msg: res.data.message,
      //     msgtype: res.data.success,
      //   });
    
    
      //   }else{   
      //     pProps.openNoticeModal({
      //       isOpen: true,
      //       msg: res.data.message,
      //       msgtype: res.data.success,
      //     });
      // }

      return res;
    });


  //Upload Certificate and Manufacturer Authorization
export const UploadManufacturerAuthorization = (fromData) =>
  api.post(`CertificateManufacturerAuthorizationUpload`, {
    action: "UploadManufacturerAuthorization",
    menukey: "certificate-manufacturer-authorization",
    ...fromData
  }, options).then((res) => res.data);

export const UploadCertificate = (fromData) =>
  api.post(`CertificateManufacturerAuthorizationUpload`, {
    action: "UploadCertificate",
    menukey: "certificate-manufacturer-authorization",
    ...fromData
  }, options).then((res) => res.data);


  export const getBrandDataById = (Data) => {
  return api.post("CertificateManufacturerAuthorizationUpload", { ...Data }, options).then((res) => {
    return res;
  });
};

export const getProductsListBrand = (params) => {
  console.log(params.queryKey[0]);
  let param = params.queryKey[0];
  return api
    .post("productListbrand", param, options)

    .then((res) => {
      return res.data;
    });
}

  export const saveCatalogSpecification = (getunitofmeasure1) =>
  api
    .post(
      `productgroup`,
      {
        ...body,
        action: "dataInsert",
        menukey: "product-group-entry",
        ...getunitofmeasure1,
      },
      options
    )
    .then((res) => res.data);
export const deleteCatalogSpecification = (id) =>
  api.post(`CatalogSpecification`, {
    ...body,
    action: "dataDalete",
    menukey: "product-specification",
    SpecificationId: id,
    UserName: UserName
  },options);
export const getCatalogSpecification = (id) =>
  api.post(
    `productgroup`,
    {
      ...body,
      action: "getProductGroup",
      menukey: "product-group-entry",
      ItemGroupId: id,
    },
    options
  );
export const upCatalogSpecification= ({ id, ...updatedunitof }) =>
  api
    .put(
      `productgroup`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "product-group-entry",
        ItemGroupId: id,
        ...updatedunitof,
      },
      options
    )
    .then((res) => {
      return res;
    });

  // Indentor
    export const getIndentorList = () =>
    api
      .post(
        "Indentor",
        {
          ...body,
          action: "getIndentorList",
          menukey: "Indentor",
          UseFor: UseFor,
        },
        options
      )
      .then((res) => {
        return res.data.datalist;
      });
  export const getIndentor = (id) =>
    api.post(
      `Indentor`,
      {
        ...body,
        action: "geIndentor",
        menukey: "Indentor",
        IndentorId: id,
        FacilityId: FacilityId,
      },
      options
    );
  export const saveIndentor = (getIndentor1) =>
    api
      .post(
        `Indentor`,
        {
          ...body,
          action: "dataInsert",
          menukey: "indentor",
          UseFor: UseFor,
          ...getIndentor1,
        },
        options
      )
      .then((res) => res.data);
  
  export const deleteIndentor = (id) =>
    api.post(`Indentor`, id, options);
  
  export const updateIndentor = ({ id, ...updateNationalSuppliersd }) =>
    api
      .put(
        `Indentor`,
        {
          ...body,
          action: "dataUpdate",
          menukey: "Indentor",
          IndentorId: id,
          ...updateNationalSuppliersd,
        },
        options
      )
      .then((res) => {
        return res;
      });    


export const gTableInfoForCatalog = (params) =>
api.post("auditlog-catalog", { params }, options).then((res) => {
  return res.data.datalist;
});

export const getAuditLogDataCatalog = (params) =>
  api.post("auditlog-catalog", { params }, options).then((res) => {
    return res.data.datalist;
  });


  export const getLogSingleRowCatalog = (params) =>
  api.post("auditlog-catalog", { params }, options).then((res) => {
    return res.data.datalist;
  });

  export const gDataBaseInfoCatalog = (params) =>
  api.post("auditlog-catalog", { params }, options).then((res) => {
    //console.log("dddddd",res.data.datalist);
    return res.data.datalist;
  }); 



  export const setCatalogStatus = ({ id, ...updatedspecificationStatus }) =>
    api
      .put(
        `CatalogSpecification`,
        {
          ...body,
          action: "specificationStatusUpdate",
          menukey: "product-specification",
          SpecificationId: id,
          UserName:UserName,
          lan: lan,
          ...updatedspecificationStatus,
        },
        options
      )
      .then((res) => {
        return res;
      });

    /* End Catalog */


    // Asset Status Entry page

    export const getassetList = () =>
  api
    .post(
      "assetstatusServer",
      { ...body, action: "getDataList", menukey: "asset-status-entry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

    export const getAssetData = (id) =>
  api.post(
    `assetstatusServer`,
    { ...body, action: "getAssetData", menukey: "asset-status-entry", MStatusId: id },
    options
  );

  export const updateassetstatus = ({ id, ...updatedasset }) =>
  api
    .put(
      `assetstatusServer`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "asset-status-entry",
        MStatusId: id,
        ...updatedasset,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveassetstaus = (mosrangeData) =>
  api
    .post(
      `assetstatusServer`,
      { ...body, action: "dataInsert", menukey: "asset-status-entry", ...mosrangeData },
      options
    )
    .then((res) => res.data);


export const deleteAssetstatus = (id) => api.post(`assetstatusServer`, id, options);



 // Asset procuring Entry page

 export const getProcuringList = () =>
 api
   .post(
     "AssetProcuring",
     { ...body, action: "getDataList", menukey: "asset-procuring" },
     options
   )
   .then((res) => {
     return res.data.datalist;
   });

   export const getProcuringData = (id) =>
 api.post(
   `AssetProcuring`,
   { ...body, action: "getAssetData", menukey: "asset-procuring", ProcuringId: id },
   options
 );

 export const updateProcuring = ({ id, ...updatedassetpro }) =>
 api
   .put(
     `AssetProcuring`,
     {
       ...body,
       action: "dataUpdate",
       menukey: "asset-procuring",
       MStatusId: id,
       ...updatedassetpro,
     },
     options
   )
   .then((res) => {
     return res;
   });

export const saveProcuring = (mosrangeData) =>
 api
   .post(
     `AssetProcuring`,
     { ...body, action: "dataInsert", menukey: "asset-procuring", ...mosrangeData },
     options
   )
   .then((res) => res.data);


export const deleteProcuring = (id) => api.post(`AssetProcuring`, id, options);

   // Fundingsource Entry page

   export const getFundingsourceList = () =>
   api
     .post(
       "FundingsourceServer",
       { ...body, action: "getDataList", menukey: "asset-status-entry" },
       options
     )
     .then((res) => {
       return res.data.datalist;
     });
 
     export const getFundingsource = (id) =>
   api.post(
     `FundingsourceServer`,
     { ...body, action: "getFundingsourceData", menukey: "funding-source-entry", FundingSourceId: id },
     options
   );
 
   export const updatefundingsource = ({ id, ...updatedfunding }) =>
   api
     .put(
       `FundingsourceServer`,
       {
         ...body,
         action: "dataUpdate",
         menukey: "funding-source-entry",
         FundingSourceId: id,
         ...updatedfunding,
       },
       options
     )
     .then((res) => {
       return res;
     });
 
 export const savefundingsource = (fundingsourceData) =>
   api
     .post(
       `FundingsourceServer`,
       { ...body, action: "dataInsert", menukey: "funding-source-entry", ...fundingsourceData },
       options
     )
     .then((res) => res.data);
 
 
 export const deleteFundingsource = (id) => api.post(`FundingsourceServer`, id, options);
 
 

 
//=================== Receive Facility ===================
export const getReceiveFacilityInvs = (params) =>
api.post("receivefacilityinvoicelist", { params }, options).then((res) => {
  return res.data.receiveinvdata;
});

export const getReceiveFacilityInv = (id) =>
api.post(
  `receiveFacilityListSingle`,
  { ...body, menukey: "receive", TransactionId: id, FacilityId: FacilityId },
  options
);

export const getNonReceivedFacilityOrderList = () =>
api
  .post(
    "receivedFacilityOrderList",
    { ...body, menukey: "receive-facility", FacilityId: FacilityId },
    options
  )
  .then((res) => {
    return res.data.receiveinvdata;
  });


export const saveReceiveFacilityInvoiceFromPickList = (formData) =>
  api
    .post(
      `receiveFacilityInvoiceInsertFromPicklist`,
      {
        ...body,
        action: "insertUpdateReceiveInvoicetPick",
        menukey: "receive-facility",
        UserName: UserName,
        OwnFacilityId: FacilityId,
        lan: lan,
        ...formData,
      },
      options
    )
    .then((res) => res.data);
  

    export const updateReceiveFacilityInv = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `receiveFacilityUpdate`,
      {
        ...body,
        menukey: "receive-facility",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

    export const postRecFacilityInvoice = (id) =>
    api
      .post(
        `POSTStockForm`,
        {
          ...body,
          menukey: "receive-facility",
          FacilityId: FacilityId,
          TableName: "recinv",
          TransactionId: id,
          TransactionTypeId: 2,
          UserName: UserName,
          lan: lan,
        },
        options
      )
      .then((res) => {
        return res;
      });


      // asset register api
      export const getassetregisterList = (params) =>
      api.post("AssetRegisterServer", { params }, options).then((res) => {
        return res.data.datalist;
      });



      export const saveassetRegister = (assetregisterData) =>
      api
        .post(
          `AssetRegisterServer`,
          { ...body, action: "dataInsert", menukey: "asset-register", ...assetregisterData },
          options
        )
        .then((res) => res.data);

      export const getAssetregisterListItem = (id) =>
      api.post(
        `AssetRegisterServer`,
        { ...body, action: "getAssetregisterData", menukey: "asset-register", AssetDetailId: id },
        options
      );
      export const deleteAssetregister = (id) => api.post(`AssetRegisterServer`, id, options);
      export const updateassetregister = ({ id, ...updatedassetregister }) =>
      api
        .put(
          `AssetRegisterServer`,
          {
            ...body,
            action: "dataUpdate",
            menukey: "asset-register",
            AssetDetailId: id,
            ...updatedassetregister,
          },
          options
        )
        .then((res) => {
          return res;
        });

        export const getAssetList = () =>api.post("AssetRegisterServer",
        { ...body, action: "getassetItemList", menukey: "asset-register" },options)
      .then((res) => {
        return res.data.productlist;
      });

      export const ItemUnpacking = (id) => api.post(`StockStatus`, id, options)

      
/* export const MCWCMonthlyForm3SubmitToUpazila = ({ id, ...updatedDispenser }) =>
api
  .put(
    `mcwc_monthly_form3`,
    {
      ...body,
      action: "MCWCMonthlyForm3SubmitToUpazila",
      menukey: "mcwc-monthly-form-3",
      UserName: UserName,
      lan: lan,
      ...updatedDispenser,
    },
    options
  )
  .then((res) => {
    return res.data;
  }); */
      
export const MCWCMonthlyForm3Unpost = ({ id, ...updatedDispenser }) =>
api
  .put(
    `mcwc_monthly_form3`,
    {
      ...body,
      action: "MCWCMonthlyForm3Unpost",
      menukey: "mcwc-monthly-form-3",
      UserName: UserName,
      lan: lan,
      ...updatedDispenser,
    },
    options
  )
  .then((res) => {
    return res.data;
  });



  //////////////////////////////////////

///MCWC Issue Voucher (Asset)
export const getMCWCIssueVoucherAsset = (params) =>
  api.post("mcwcassetissuefromstore", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveMCWCIssueVoucherAssetInv = (dispenserData) =>
  api
    .post(
      `mcwcassetissuefromstore`,
      {
        ...body,
        action: "MCWCIssueVoucherAssetSave",
        menukey: "mcwcassetissuefromstore",
        UserName: UserName,
        lan: lan,
        ...dispenserData,
      },
      options
    )
    .then((res) => res.data);

export const MCWCissueVoucherAssetUpdate = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `mcwcassetissuefromstore`,
      {
        ...body,
        action: "MCWCIssueVoucherAssetUpdate",
        menukey: "mcwcassetissuefromstore",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getMCWCIssueVoucherAssetSingle = (id) =>
  api.post(
    `mcwcassetissuefromstore`,
    {
      ...body,
      action: "getMCWCIssueVoucherAssetSingle",
      menukey: "mcwcassetissuefromstore",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );
export const deleteMCWCIssueVoucherAssetInv = (id) =>
  api
    .post(
      `mcwcassetissuefromstore`,
      {
        ...body,
        menukey: "mcwcassetissuefromstore",
        action: "deleteMCWCIssueVoucherAssetInv",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res.data;
    });
export const postMCWCIssueVoucherAsset = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "mcwcassetissuefromstore",
        FacilityId: FacilityId,
        TableName: "indent",
        TransactionId: id,
        TransactionTypeId: 2,
        UserName: UserName,
        lan: lan,
      },
      options
    )
    .then((res) => {
      return res;
    });


  export const getAssetsLot = (id) =>
    api
      .post(
        "assetslot",
        { ...body, action: "getAssetLot", menukey: "products", FacilityId: id },
        options
      )
      .then((res) => {
        console.log("id from lot modal: ", id);
        return res.data.productlist;
      });
///////////////////////////////////////////

/* get MCWC Software Start Date for MCWC adjustment*/
export const getMCWCSoftwareStartDate = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `AdjustmentFacilityInvoice`,
      {
        ...body,
        action: "getMCWCSoftwareStartDate",
        menukey: "adjustment-facility",
        UserName: UserName,
        lan: lan,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });
       
    // asset register global api
    export const getassetregisterglobalList = (params) =>
    api.post("MCWCAssetRegisterGlobalServer", { params }, options).then((res) => {
      return res.data.datalist;
    });

    //Receive Summary Report Report Api
    export const getReceiveSummaryReport = (params) =>
      api.post("ReceiveSummaryReport", { params }, options).then((res) => {
        return res.data.datalist;
    });

    //MCWC Receive Summary Report Report Api
    export const getMCWCReceiveSummaryReport = (params) =>
      api.post("ReceiveSummaryReport", { params }, options).then((res) => {
        return res.data.datalist;
    });

     //Receive Summary Report Report Global Api
     export const getReceiveSummaryReportGlobal = (params) =>
      api.post("ReceiveSummaryReport", { params }, options).then((res) => {
        return res.data.datalist;
    });




    
/*Start of MCWC National Dashboard*/

export const getMCWCCurrentMetaInfoNational = (currMonthId,currYearId,pFacilityCode = 0) => {
  console.log('params-----------------------',currMonthId,'==',currYearId);
  return api
    .post(
      "mcwc_dashboard_national",
      {
        ...body,
        action: "getMCWCDashboardMetaInfo",
        menukey: "mcwc-dashboard-national",
        FacilityId: FacilityId,
        MonthId: currMonthId,
        YearId: currYearId,
        FacilityCode: pFacilityCode,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};


export const getMCWCPendingReceiveDataNational = () => {
  return api.post("mcwc_dashboard_national", 
    { ...body, 
	action: "getMCWCPendingReceiveData", 
	menukey: "mcwc-dashboard-national", 
	FacilityId: FacilityId, 
	}, options)
    .then((res) => {
      return res.data.datalist;
    });
}

export const getMCWCTotalStockDataNational = (pFacilityCode = 0) => {

  return api.post("mcwc_dashboard_national", 
    { ...body, 
	action: "getMCWCTotalStockDataNational", 
	menukey: "mcwc-dashboard-national", 
	FacilityId: FacilityId, 
  FacilityCode: pFacilityCode,
	}, options)
    .then((res) => {
      return res.data.datalist;
    });
}

// export const getMCWCTotalStockByFacilityMapDataChartNational = () => {
//   return api
//     .post(
//       "mcwc_dashboard_national",
//       {
//         ...body,
//         action: "getMCWCTotalStockByFacilityMapDataChartNational",
//         menukey: "mcwc-dashboard-national",
//         FacilityId: FacilityId,
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };
export const getMCWCTotalStockByFacilityDataNational = (pFacilityCode = 0, pItemCode) => {
console.log("App.js pItemCode ",pItemCode);
  return api.post("mcwc_dashboard_national", 
    { ...body, 
	action: "getMCWCTotalStockByFacilityDataNational", 
	menukey: "mcwc-dashboard-national", 
	FacilityId: FacilityId, 
  FacilityCode: pFacilityCode,
  ItemCode: pItemCode,
	}, options)
    .then((res) => {
      return res.data.datalist;
    });
}

export const getMCWCWaitingForReceiveDataNational = () => {

  return api.post("mcwc_dashboard_national", 
    { ...body, 
	action: "getMCWCWaitingForReceiveData", 
	menukey: "mcwc-dashboard-national", 
	FacilityId: FacilityId, 
	}, options)
    .then((res) => {
      return res.data.datalist;
    });
}

export const getNumberOfAssetStatusDataNational = (pFacilityCode = 0) => {

  return api.post("mcwc_dashboard_national", 
    { ...body, 
	action: "getNumberOfAssetStatusData", 
	menukey: "mcwc-dashboard-national", 
	FacilityId: FacilityId, 
  FacilityCode: pFacilityCode,
	}, options)
    .then((res) => {
      return res.data.datalist;
    });
}

export const getMCWCCurrentStockStausChartNational = (pFacilityCode = 0) => {
  return api
    .post(
      "mcwc_dashboard_national",
      {
        ...body,
        action: "getMCWCCurrentStockStausChart",
        menukey: "mcwc-dashboard-national",
        FacilityId: FacilityId,
        FacilityCode: pFacilityCode,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getMCWCExpiryDataNational = (pFacilityCode = 0) => {
  return api
    .post(
      "mcwc_dashboard_national",
      {
        ...body,
        action: "getMCWCExpiryData",
        menukey: "mcwc-dashboard-national",
        FacilityId: FacilityId,
        FacilityCode: pFacilityCode,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getMCWCAssetStatusDataChartNational = (pFacilityCode = 0) => {
  return api
    .post(
      "mcwc_dashboard_national",
      {
        ...body,
        action: "getMCWCAssetStatusDataChartNational",
        menukey: "mcwc-dashboard-national",
        FacilityId: FacilityId,
        FacilityCode: pFacilityCode,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};


export const getMCWCDispenseStatusViewDataChartNational = (pFacilityCode = 0) => {
  return api
    .post(
      "mcwc_dashboard_national",
      {
        ...body,
        action: "getMCWCDispenseStatusViewDataChartNational",
        menukey: "mcwc-dashboard-national",
        FacilityId: FacilityId,
        FacilityCode: pFacilityCode,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

/* End Off MCWC National Dashboard */